import React, { FunctionComponent } from 'react';

const SignHistory: FunctionComponent = () => {
    return (
        <svg height="20px" version="1.1" viewBox="0 0 22 22" width="20px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <style type="text/css"></style>
            <g className="st0" id="grid_system" />
            <g id="_icons">
                <g>
                    <path d="M12,19c-3.9,0-7-3.1-7-7s3.1-7,7-7c2,0,3.9,0.9,5.2,2.3l-1.8,0.3c-0.5,0.1-0.9,0.6-0.8,1.2c0.1,0.5,0.5,0.8,1,0.8    c0.1,0,0.1,0,0.2,0l4.2-0.8c0.5-0.1,0.9-0.6,0.8-1.2L20,3.5c-0.1-0.5-0.6-0.9-1.2-0.8c-0.5,0.1-0.9,0.6-0.8,1.2l0.3,1.7    C16.6,4,14.4,3,12,3C8.7,3,5.8,4.8,4.2,7.5c0,0,0,0,0,0c0,0,0,0,0,0C3.4,8.9,3,10.4,3,12s0.4,3.1,1.2,4.5c0,0,0,0,0,0c0,0,0,0,0,0    C5,17.9,6.1,19,7.5,19.8c0,0,0,0,0,0c0,0,0,0,0,0C8.9,20.6,10.4,21,12,21c0.4,0,0.8-0.3,0.9-0.6C13,20.3,13,20.1,13,20    c0-0.1,0-0.3-0.1-0.4C12.8,19.3,12.4,19,12,19z" /><circle cx="20" cy="12" r="1" /><ellipse cx="18.9" cy="16" rx="1" ry="1" transform="matrix(0.5 -0.866 0.866 0.5 -4.3923 24.3923)" /><ellipse cx="16" cy="18.9" rx="1" ry="1" transform="matrix(0.866 -0.5 0.5 0.866 -7.3205 10.5359)" /><path d="M13,11.6V9c0-0.6-0.4-1-1-1s-1,0.4-1,1v3c0,0.3,0.1,0.5,0.3,0.7l2,2c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3    c0.4-0.4,0.4-1,0-1.4L13,11.6z" />
                </g>
            </g>
        </svg>
    );
}

export default SignHistory;
