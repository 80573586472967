import {FunctionComponent} from 'react';
import {BrowserRouter, Route, Switch, Redirect, useLocation, useHistory} from 'react-router-dom';
import Example from "../presentation/page/Example";

import PrivateRoute from './PrivateRoute';

// Page
import Profil from '../presentation/page/Profil';
import Ui from '../presentation/page/Ui'
import Form from '../presentation/page/Form';
import Folder from "../presentation/page/Folder";
import ManageFolder from "../presentation/page/ManageFolder";
import DocusignCallback from "../presentation/page/DocusignCallback";
import Dashboard from '../presentation/page/Dashboard';
import CreateFolderClient from '../presentation/page/CreateFolderClient';

import envVariable from "../presentation/util/envVariable";
import Authentication from "../oauth/Authentication";
import Caller from '../domain/Api/Caller';
import DashboardDossier from '../presentation/page/DashboardDossier'

const Authorization: FunctionComponent = () => {
    const location = useLocation();
    const code = new URLSearchParams(location.search).get("code");
    const history = useHistory()

    Authentication.login(code).then(
        async function success(greatAccess) {
            if (greatAccess) {
                await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/users/me`, {}).then(response => {
                    localStorage.setItem('profile', JSON.stringify({
                        'username': response.user.username,
                        'firstname': response.user.firstname,
                        'lastname': response.user.lastname
                    }))
                    return localStorage.setItem('role', response.user.role)
                }).then(() => {
                    window.location.pathname = envVariable('REACT_APP_REDIRECT_URL')
                })
            }
        }
    )
    return (
        <></>
    );
};

const Loggout: FunctionComponent = () => {
    Authentication.logout().then(
        function success() {
            window.location.replace(`${envVariable('REACT_APP_OAUTH_LOGOUT_URL')}`);
        }
    )
    return (
        <></>
    );
}

const CheckSSO: FunctionComponent = () => {
    Authentication.checkConnectedUser().then((reconnect) => {
        if (reconnect) {
            Authentication.logout().then(() => {
                if(!Authentication.authorize(true)) {
                    window.location.replace(`/logout`)
                }  
            })
        } else {
            Authentication.isLogged().then(isAuthenticated => {
                if (!isAuthenticated) {
                    if(!Authentication.authorize(true)) {
                        window.location.replace(`/logout`)
                    }
                } else {
                    window.location.pathname = envVariable('REACT_APP_REDIRECT_URL')
                }
            })
        
        }
    })

    return (
        <></>
    );
}

const Router: FunctionComponent = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/ui" component={Ui} />
                <Route path="/authorization" component={Authorization}/>
                <Route path="/logout" component={Loggout}/>
                <Route path="/sso-check" component={CheckSSO}/>
                <Route path="/docusign/success" component={DocusignCallback}/>
                <PrivateRoute path="/form/:pageId?/:blockId?" component={Form}/>
                <PrivateRoute path="/dossier/:state?/:currentPage?" component={Folder}/>
                <PrivateRoute path="/manage-dossier/statut-integration/:state?/:currentPage?" component={ManageFolder} roles={['manage_dossier']}/>
                <PrivateRoute path="/manage-dossier/:dossierId" component={DashboardDossier} roles={['manage_dossier']}/>
                <PrivateRoute path="/tableau-de-bord/:currentTab" component={Dashboard}/>
                <PrivateRoute path="/tableau-de-bord/" component={Dashboard}/>
                <PrivateRoute path="/creer-un-dossier-client/" component={CreateFolderClient}/>
                <PrivateRoute exact path="/profile">
                    <Profil/>
                </PrivateRoute>
                <Route path="/example" component={Example}/>
                {/* Not Found */}
                <PrivateRoute component={() => <Redirect to="/dossier"/>}/>
            </Switch>
        </BrowserRouter>
    );
};

export default Router;
