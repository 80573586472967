import React, { FunctionComponent, useEffect, useState } from 'react'

import BlockState from './BlockState'
import ModifyComponent from './call-to-action/ModifyComponent'
import Block from '../../../domain/Block/Block'

import FormGateway from '../../../gateway/Form/FormGateway'
import FindUseCase from '../../../domain/Form/FindUseCase'
import FetchElementGateway from '../../../gateway/Element/FetchElementGateway'
import ElementsPreviewPresenter from '../../../domain/Element/ElementsPreviewPresenter'
import ElementPreview from '../../../domain/Element/ElementPreview'
import ValidationComponent from './call-to-action/ValidationComponent'

import parse from 'html-react-parser';
import envVariable from "../../util/envVariable";
import storage from '../../util/storage';
import { roleBackOfficeList } from '../../../domain/User/Erole'
import ReferentialGateway from '../../../domain/Referential/ReferentialGateway'
import GetReferential from '../../../useCase/Referential/GetReferential'

type Props = {
    pageId: string,
    block: Block,
    state: string,
    role: string
}

const BlockPreviewComponent: FunctionComponent<Props> = ({ pageId, block, state, role }) => {
  const stateClass = BlockState.getStateClass(state)
  const [formData, setFormData] = useState<ElementPreview[] | null>([])
  const isBackOfficeUser = roleBackOfficeList.includes(role)

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    let isMounted = true

    const refentialGateway = new ReferentialGateway()
    const getRefential = new GetReferential(refentialGateway)

    getRefential.execute(null, block.id).then((referential) => {
      if (!isMounted) return
      const customer = envVariable('REACT_APP_CUSTOMER')
      const productCode = new FormGateway().getValueForElementId(referential?.productId ?? '') ?? ''

      if (state !== 'disable') {
        const fetchElementGateway = new FetchElementGateway()
        fetchElementGateway.getElementsByBlockId(block.id, productCode, customer, signal)
          .then((elements) => {
            if (!isMounted) return
            const findUseCase = new FindUseCase(new FormGateway())
            findUseCase.execute().then((data) => {
              if (isMounted && data !== null) {
                setFormData(new ElementsPreviewPresenter().execute(elements, data))
              }
            })
          })
          .catch(error => {
            if (error.name !== 'AbortError') {
              console.error('Erreur lors du chargement des éléments', error)
            }
          })
      }
    })

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [pageId, block.id, state])


  const [stateAnimationClass, setStateAnimationClass] = useState<string>('slideDown')

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (block.state === 'validate' || block.state === 'complete') {
        setStateAnimationClass('slideDown')
      }
    }, 1)

    return () => clearTimeout(timeoutId)
  }, [block.state])

  const dossierState = storage.getDossierState()

  return (
    <div className={`${stateAnimationClass}`}>
      <div className={`card ${stateClass} u-mxAuto`}>
        <div className="card__first">
          <h3 className="card__title">{block.title}</h3>
          <div className="card__content flex-container">
            <p className="col-md-12">
              {
                formData != null
                && formData.map(
                  (data, index) => {
                    return (
                      data.presentation
                      && <span key={index}>{parse(data.presentation)} <br/> </span>
                    )
                  }
                )
              }
            </p>
          </div>
        </div>
        {
          //role === 'bo' && <ValidationComponent blockId={block.id}/>
          (role === 'bo' || isBackOfficeUser ) && <ModifyComponent blockId={block.id} pageId={pageId}/>
        }
        {
          role !== 'bo' && !isBackOfficeUser && (dossierState === 'ongoing' || dossierState === '') && <ModifyComponent blockId={block.id} pageId={pageId}/>
        }
      </div>
    </div>
  )
}

export default BlockPreviewComponent
