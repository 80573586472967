import { FC, InputHTMLAttributes, default as React, useState } from 'react'
import { IFormFieldProps } from '../../IFormFieldProps';
import FieldErrorMessage from '../messages/FieldErrorMessage';
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import Element from '../../../../domain/Element/Element';
import SaveUseCase from '../../../../domain/Form/SaveUseCase';
import FormGateway from '../../../../gateway/Form/FormGateway';
import getElementsIdToRender from '../../../util/getElementsIdToRender';
import { updateElementTimestamp } from '../../../../store/element/elements';

// Datepicker
import Flatpickr from 'react-flatpickr'
import { French } from 'flatpickr/dist/l10n/fr';
import 'flatpickr/dist/themes/material_green.css';

// Datepicker Custom
import CalendarInput from '../../svg/CalendarInput'

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  id?: string,
  classes: string,
  minDateString?: string,
  maxDateString?: string,
  name: string,
  defaultValue?: string,
  clearErrors: (id: string | undefined) => void
}

const DatePickerSimple: FC<IProps> = ({
  id,
  classes,
  label,
  name,
  minDateString,
  defaultValue,
  maxDateString,
  register,
  clearErrors,
  error,
  ...rest
}) => {

  const [selectedDate, handleDateChange] = useState(defaultValue);

  const minDate = (minDateString) ? new Date(minDateString) : null;
  const maxDate = (maxDateString) ? new Date(maxDateString) : null;
  
  const dispatch = useAppDispatch()
  const elementsWithConditionJSON = useAppSelector(state => state.elements.currentElementsWithCondition)
  const elementsWithCondition: Element[] = JSON.parse(elementsWithConditionJSON)
  const elementsWithCalculeJSON = useAppSelector(state => state.elements.currentElementsWithCalcule)
  const elementsWithCalcule: Element[] = JSON.parse(elementsWithCalculeJSON)
  const elementsWithReferenceJSON = useAppSelector(state => state.elements.currentElementsWithReference)
  const elementsWithReference: Element[] = JSON.parse(elementsWithReferenceJSON)

  const handleChange = (selectedDate) => {
    clearErrors(id)
    
    if (undefined !== selectedDate[0]) {
      handleDateChange(selectedDate[0].toLocaleDateString('fr-FR'))

      if (undefined !== id) {
        const data = [];
        data[id] = selectedDate[0].toLocaleDateString('fr-FR');

        const saveUseCase = new SaveUseCase(new FormGateway())
        saveUseCase.execute(data).then(() => {
          const elementsIdToRender: string[] = getElementsIdToRender(elementsWithCondition, id, elementsWithCalcule, elementsWithReference)
          elementsIdToRender.map(elementIdToRender => dispatch(updateElementTimestamp({ 'id': elementIdToRender })))
        })
      }
    }
  }

  return (
    <div className={`${classes}  ${rest.readOnly && "readonly-element"}`} title={rest.help}>
      <div className={`datepicker__wrapper ${error && 'error'}`}>
        <input className={'checkbox-toggle'} name={id} ref={register} value={selectedDate} id={name} readOnly />
        <label htmlFor={id}>{label}</label>
        <CalendarInput />
        <Flatpickr
          className="form-control"
          options={{
            minDate: minDate,
            maxDate: maxDate,
            altFormat: 'd/m/Y',
            dateFormat: 'd/m/Y',
            locale: French,
            defaultDate: defaultValue,
            altInput: true,
            allowInput: true,
          }}
          placeholder="jj/mm/AAAA"
          selected={selectedDate}
          onChange={handleChange}
        />
        {error?.message && <FieldErrorMessage message={error.message} />}
      </div>
    </div>
  )
};

export default DatePickerSimple;
