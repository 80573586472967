import SmallerThanInterface from './SmallerThanInterface';

export default class SmallerThan implements SmallerThanInterface
{
  validate(valueToCompare: string, secondValue: unknown, type?: string): boolean {
    if (type === 'input_date' && secondValue !== undefined) {
      const [day, month, year] = String(secondValue).split("/");
      const date = new Date(Number(year), Number(month) - 1, Number(day));
      const today = new Date()

      if (isNaN(date.getTime())) {
        return false;
      }

      let age = today.getFullYear() - date.getFullYear();

      const birthMonth = date.getMonth();
      const birthDay = date.getDate();
      if (today.getMonth() < birthMonth || (today.getMonth() === birthMonth && today.getDate() < birthDay)) {
          age--;
      }
      return age < Number(valueToCompare);
    }

    if (!isNaN(Number(secondValue))) {
      return Number(valueToCompare) > Number(secondValue);
    }
    return false
  }
}
