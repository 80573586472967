import {OperatorDefinition} from './OperatorDefinition';
import EqualInterface from './EqualInterface';
import Equal from './Equal';
import NotEqual from './NotEqual';
import GreaterThan from './GreaterThan';
import GreaterEqual from './GreaterEqual';
import SmallerThan from './SmallerThan';
import SmallerEqual from './SmallerEqual';
import Contains from './Contains';
import NotContains from './NotContains';

export default class OperatorFactory
{
  static instance(operator: string): EqualInterface {
    switch (operator) {
      case OperatorDefinition.EQUAL:
        return new Equal
      case OperatorDefinition.NOT_EQUAL:
        return new NotEqual
      case OperatorDefinition.GREATER:
        return new GreaterThan
      case OperatorDefinition.GREATER_EQUAL:
        return new GreaterEqual
      case OperatorDefinition.SMALLER:
        return new SmallerThan
      case OperatorDefinition.SMALLER_EQUAL:
        return new SmallerEqual
      case OperatorDefinition.CONTAINS:
        return new Contains
      case OperatorDefinition.NOT_CONTAINS:
        return new NotContains  
      default:
        return new Equal
    }
  }
}
