import ContainsInterface from './ContainsInterface';

export default class Contains implements ContainsInterface {
    private isArrayString(str: string): boolean {
        try {
            JSON.parse(str)
        } catch (e) {
            return false
        }
        return true
    }

    validate(valueToCompare: string, secondValue: Array<unknown>|string, type?: string): boolean {
        if (secondValue === undefined)
            return false

        if ('select' !== type) {
            return false;
        }

        if (Array.isArray(secondValue)) {
            if (this.isArrayString(valueToCompare)) {
                const arrToCompare = JSON.parse(valueToCompare);
                return arrToCompare.every(item => secondValue.includes(item))
            } else if (valueToCompare) {
                return secondValue.includes(valueToCompare.toString())
            }
        } else {
            return secondValue === valueToCompare
        }
        return false
    }
}
