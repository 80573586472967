// React
import React, {FunctionComponent, InputHTMLAttributes, useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';

// Src
import envVariable from "../../util/envVariable";
import storage from '../../util/storage';
import { isFolderDuplicationEnabled } from '../../util/envVariable';

// Redux
import {updateProgressValueById, updateNextPageId, updateNextPageIdAndCurrentPageIddByGoBack, isBack, updateProgressValueByIdGoBack, getFirstPage} from '../../../store/wizzard/wizzardStep';
import {useAppDispatch, useAppSelector} from '../../../store/hook'
import {updateBlock} from '../../../store/block/blocks';
import {setTimestamp} from '../../../store/header/header';
import {setCurrent} from '../../../store/element/elements';

// Presentation
import Loader from '../loader/Loader';
import ElementFactory from '../elements/ElementFactory';
import Modal from '../modal/Modal';
import FolderModal from '../folder/FolderComponent/FolderModal';
import PageLoader from '../pageLoader/PageLoader';

// Domain
import Block from '../../../domain/Block/Block';
import Element from '../../../domain/Element/Element';
import {EElementType} from "../../../domain/Element/EElementType";
import {EFolderStatus} from "../../../domain/Folder/EFolderStatus";
import PostUSeCase from '../../../domain/Form/PostUseCase'
import FindUseCase from '../../../domain/Form/FindUseCase';

// Gateway
import FetchElementGateway from '../../../gateway/Element/FetchElementGateway';
import FormGateway from '../../../gateway/Form/FormGateway';
import LocalStorageGateway from '../../../gateway/Form/LocalStorageGateway';
import FetchFolderGateway from '../../../gateway/Folder/FetchFolderGateway';
import FetchDocumentGateway from '../../../gateway/Document/FetchDocumentGateway';
import GeneratedDocument from '../../../domain/Document/GeneratedDocument';
import IconFactory from '../svg/IconFactory';
import useModal from '../modal/useModal';
import { gtmTracker } from '../../../gtm';
import Preview from '../svg/Preview'
import { roleBackOfficeList, ERole } from '../../../domain/User/Erole';

import GetDetailAndStoreItUseCase from '../../../useCase/Folder/GetDetailAndStoreItUseCase';
import GetReferential from '../../../useCase/Referential/GetReferential';
import ReferentialGateway from "../../../domain/Referential/ReferentialGateway";

type Props = {
    pageId: string,
    block: Block,
};

const VOISIN_BLOCK_WELCOME = 'c2fc93ff-1e3e-499e-a060-a111668601b5';
const VOISIN_BLOCK_FINAL = 'e497fd89-4b40-4e99-9e7a-b2f63ec9d669';
const VOISIN_BLOCK_MISSION_VALIDATION = 'e176f758-ec12-49d3-92aa-0a8e5b63986c';
const VOISIN_BLOCK_SIGNATURE = 'cecfa1cc-748f-4998-a20c-8a909a6a6781';

/**
 * Displays in form mode the elements inherent to the current block
 * @param pageId
 * @param block
 * @constructor
 */
const BlockFormComponent: FunctionComponent<Props> = (
    {
        pageId,
        block
    }
) => {

    interface ICustomerElementIds {
        [key: string]: string | null
    }

    const dispatch = useAppDispatch()
    const history = useHistory()
    const {t} = useTranslation()

    const {register, handleSubmit, setError, errors, clearErrors, control} = useForm()
    const [elements, setElements] = useState<Element[] | null>(null);
    const [formData, setFormData] = useState<Record<string, unknown> | [] | null>(null);
    const [isScrolled, setIsScrolled] = useState<boolean>(false);
    const [genericError, setGenericError] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false)
    const [documents, setDocuments] = useState<GeneratedDocument[] | null>(null);

    const storeWizardStepNextPageId = useAppSelector(state => state.wizzardStep.nextPageId)
    const storeBlocksVisible = useAppSelector(state => state.blocks.blocksVisible)
    const storeBlocksVisibleLength = useAppSelector(state => state.blocks.blocksVisibleLength)
    const storeWizardStepPrevPageId = useAppSelector(state => state.wizzardStep.prevPageId)

    const blockRef = useRef<HTMLFormElement>(null)

    const [isDocumentPreview, toggleDocumentPreview] = useModal();
    const [isInitDocPreview, setInitDocPreview] = useState<boolean>(false);
    const [isDownloading, setDownloading] = useState<boolean>(false);
    const [contentModal, setContentModal] = useState<InputHTMLAttributes<string>>();
    const [previewDocument, setPreviewDocument] = useState<number>()
    const [documentName, setDocumentName] = useState('')
    const [isShowingDuplicate, toggleDuplicate] = useModal()
    const [isLoadingDuplicate, setLoadingDuplicate] = useState<boolean>(false)
    const roleHideButtonDuplicate: string[] = [ERole.Client, ERole.Prospect, ERole.DirectProspect, ERole.Back_office_user, ERole.ManageDossier]

    const handleClick = () => {
        if (null !== blockRef.current && window.scrollTo) {
            window.scrollTo({top: (blockRef?.current?.offsetTop || 0) - 100, behavior: 'smooth'})
            setIsScrolled(true)
            dispatch(isBack({'back': false}))
        }
    }

    useEffect(() => {
        const refentialGateway = new ReferentialGateway();
        const getRefential = new GetReferential(refentialGateway);
        getRefential.execute(null, block.id)
        .then((referential) => {
            dispatch(getFirstPage());

            const formGateway = new FormGateway()
            const productCode: string = (formGateway).getValueForElementId(referential?.productId ?? '') ?? ''
            const customer = envVariable('REACT_APP_CUSTOMER');

            const fetchElementGateway = new FetchElementGateway();
            const fetchDocumentGateway = new FetchDocumentGateway();

            fetchDocumentGateway.getGeneratedDocuments(block.id).then(
                documents => {
                    setDocuments(documents)
                }
            )
            .catch(() =>
                setDocuments(null)
            )

            fetchElementGateway.getElementsByBlockId(block.id, productCode, customer).then(
                elements => {
                    dispatch(
                        setCurrent(
                            {
                                'currentElements': JSON.stringify(elements),
                                'currentElementsWithCondition': JSON.stringify(
                                    elements?.filter(
                                        (element) => undefined != element.condition
                                    )
                                ),
                                'currentElementsWithCalcule': JSON.stringify(
                                    elements?.filter(
                                        (element) => undefined != element.calculate && '' !== element.calculate)
                                ),
                                'currentElementsWithReference': JSON.stringify(
                                    elements?.filter(
                                        (element) => undefined != element.reference && '' !== element.reference)
                                )
                            }
                        )
                    );
                    setElements(elements)
                    const findUseCase = new FindUseCase(formGateway);
                    findUseCase.execute().then(
                        (data) => {
                            setFormData((data) ?? [])
                            if (!isScrolled) {
                                handleClick()
                            }
                        }
                    )
                }
            )
        });
   }, [dispatch, pageId, block.id]);


    const handleChange = (elementId: string, value: boolean|number|string|string[]|null) => {
        const newFormData = {}
        newFormData[elementId] = value
        setFormData(formData => ({...formData, ...newFormData}))
    }
    /**
     * Manage Block Submit Event
     * @param data
     * @param e
     */
    const onSubmit = async (
        data,
        e
    ) => {
        /**
         * Kludge to avoid unexpected on value for toogle element when elements are duplicated due to
         * multiple conditions
         */
        if (parentsOnlyElements) {
            const checkBoxElements = parentsOnlyElements?.filter((element) => EElementType.TOGGLE === element.type);
            const formGateway = new FormGateway()
            const findUseCase = new FindUseCase(formGateway);
            const storageData =  await findUseCase.execute()
            if (storageData) {
                for(let i=0 ; i<checkBoxElements?.length ; i++) {
                    if (typeof data[checkBoxElements[i].id] !== 'undefined'
                        && typeof data[checkBoxElements[i].id] !== 'boolean'
                        &&  typeof data[checkBoxElements[i].id] !== 'string'
                    ) {
                        data[checkBoxElements[i].id] = storageData[checkBoxElements[i].id]
                    }
                }
            }
        }
        const role = localStorage.getItem('role');

        setLoading(true)
        let hasErrors = false
        /* BEGIN: ATLAND VALIDITY CHECK ONLY FOR PRODUCTION */
        if (block.id === '48ea4516-ae8a-4030-9aad-661295b74c01') {

            const inputFilesList = document.querySelectorAll('input[type=file]');
            const uploadersWithMultipleOptions = ["64b9a000-ce7b-4ce6-9ba8-d5ef9bed7032"];

            inputFilesList.forEach(function (inputFile) {
                if (!storage.getReceipts(inputFile.id) && inputFile.id !== '09ae70ed-3c78-403d-9993-3ca7b14ae147' && !(inputFile.id in uploadersWithMultipleOptions)) {
                    hasErrors = true
                    setError(inputFile.id, {
                        type: "manual",
                        message: "Merci de téléverser ce document obligatoire"
                    })
                }
            });

            uploadersWithMultipleOptions.map(id => {
                if ((!storage.getReceipts(id))) {
                    hasErrors = true;
                    setError(id, {
                        type: "manual",
                        message: "Merci de téléverser ce document obligatoire"
                    })
                }
            });
        }

        if (block.id === '968757f1-390b-4d94-9773-6b18c57a2f1e') {
            if (!storage.getReceipts('1715a908-08e3-4dfd-927a-57ba84d8fbea')) {
                hasErrors = true
                setError('1715a908-08e3-4dfd-927a-57ba84d8fbea', {
                    type: "manual",
                    message: "Merci de téléverser ce document obligatoire"
                })
            }

            if (!storage.getReceipts('2fbfde04-4a3a-4b99-b458-c7d3c63d460b')) {
                hasErrors = true
                setError('2fbfde04-4a3a-4b99-b458-c7d3c63d460b', {
                    type: "manual",
                    message: "Merci de téléverser ce document obligatoire"
                })
            }
        }
        /* END: ATLAND VALIDITY CHECK ONLY FOR PRODUCTION */

        /**
         * Mise en place d'une condition temporaire pour le JOF si sous > 30k
         * Client: Swisslife
         */
        if (block.id === 'd3e4e196-710d-481f-8e49-f62d549b1d8f') {
            const formDatasJSON = localStorage.getItem('form')
            const customer = envVariable('REACT_APP_CUSTOMER')
            if (formDatasJSON != null && customer === 'swisslife-am') {
                const formDatasSaved = JSON.parse(formDatasJSON)
                let required = false
                if(formDatasSaved['e3562c0b-63d9-4ef8-a4c4-7642b81224f7']) {
                    const montantSous = parseFloat(formDatasSaved['e3562c0b-63d9-4ef8-a4c4-7642b81224f7'])
                    if (montantSous > 30000) {
                        required = true
                    }
                } else if(formDatasSaved['31d1a781-d961-4a20-b4e2-f3a462cffe21']) {
                    if (formDatasSaved['31d1a781-d961-4a20-b4e2-f3a462cffe21'] === 'Plein Air Invest'
                        || formDatasSaved['31d1a781-d961-4a20-b4e2-f3a462cffe21'] === 'PI') {
                            required = true
                        }
                }
                if(required) {
                    if (!storage.getReceipts('7782bb34-9438-4783-a397-4272ca32e50f')) {
                        hasErrors = true
                        setError('7782bb34-9438-4783-a397-4272ca32e50f', {
                            type: "manual",
                            message: "Merci de téléverser ce document obligatoire"
                        })
                    }
                }
            }
        }

        /**
         * Mise en place d'une condition temporaire pour le JOF si sous >= 50k
         * Client: Aestiam
         */
        if (block.id === 'eabb7748-3df5-451b-b780-db0fa23aa462' ||
            block.id === '23ec9ce2-25a7-402c-bc9b-b619b06da74e'
        ) {
            const formDatasJSON = localStorage.getItem('form')
            const customer = envVariable('REACT_APP_CUSTOMER')
            const selectId = block.id === '23ec9ce2-25a7-402c-bc9b-b619b06da74e' ? '77737bc7-2bec-43d5-82b7-7414b4d9c2f7' : 'fd4ea8f5-4701-49ce-97db-fddde311af68'
            const radioId = block.id === '23ec9ce2-25a7-402c-bc9b-b619b06da74e' ? 'dc9c8ac1-fc9c-4cd6-af39-20a17589a6d6' : '713b6525-6f4d-4350-a1f1-cd918d11ebc4'
            if (formDatasJSON != null && customer === 'aestiam') {
                const formDatasSaved = JSON.parse(formDatasJSON)
                if(formDatasSaved['e3562c0b-63d9-4ef8-a4c4-7642b81224f7']) {
                    const montantSous = parseFloat(formDatasSaved['e3562c0b-63d9-4ef8-a4c4-7642b81224f7'])
                    if (montantSous >= 50000) {
                        if (!formDatasSaved[selectId]?.length) {
                            hasErrors = true
                            setError(selectId, {
                                type: "manual",
                                message: "Veuillez sélectionner au moins un champ"
                            })
                        }
                        if (!data[radioId]) {
                            hasErrors = true
                            setError(radioId, {
                                type: "manual",
                                message: "Veuillez sélectionner une réponse"
                            })
                        }
                    }
                }
            }
        }

        /**
         * Mise en place d'une condition temporaire :
         * vérifier que les numéros de téléphones du souscripteur et du co-souscripteur sont différents
         */
        if (data['d1f92caa-69e5-4042-995f-298c3e0bc802']) {
            const formDatasJSON = localStorage.getItem('form');

            if (formDatasJSON != null) {
                const formDatasSaved = JSON.parse(formDatasJSON)
                if (
                    formDatasSaved['06fc9276-9279-11eb-a8b3-0242ac130003']
                    && formDatasSaved['06fc9276-9279-11eb-a8b3-0242ac130003'] === data['d1f92caa-69e5-4042-995f-298c3e0bc802']
                ) {
                    hasErrors = true
                    setError('d1f92caa-69e5-4042-995f-298c3e0bc802', {
                        type: "manual",
                        message: "Pour pouvoir signer la souscription en ligne, il est nécessaire d'avoir 2 numéros de téléphones différents"
                    })
                }
            }
        }

        /**
         * Mise en place d'une condition temporaire :
         * vérifier que les numéros de téléphones du souscripteur et du conjoint sont différents
         */
        if (data['b304fd9a-e0e5-433c-9c04-19c4fec52e49']) {
            const formDatasJSON = localStorage.getItem('form');

            if (formDatasJSON != null) {
                const formDatasSaved = JSON.parse(formDatasJSON)
                if (
                    formDatasSaved['06fc9276-9279-11eb-a8b3-0242ac130003']
                    && formDatasSaved['06fc9276-9279-11eb-a8b3-0242ac130003'] === data['b304fd9a-e0e5-433c-9c04-19c4fec52e49']
                ) {
                    hasErrors = true
                    setError('b304fd9a-e0e5-433c-9c04-19c4fec52e49', {
                        type: "manual",
                        message: "Le téléphone du conjoint doit être différents du téléphone du souscripteur"
                    })
                }
            }
        }


        // ...................................................................................................... Voisin

        const VOISIN_SUBSCRIBER_EMAIL_ELEMENT_ID = "06fc91ae-9279-11eb-a8b3-0242ac130003"
        const VOISIN_CO_SUBSCRIBER_EMAIL_ELEMENT_ID = "1d5ef763-36b2-4b58-99d1-5b64eee36ca7"
        const VOISIN_SUBSCRIBER_SPOUSE_EMAIL_ELEMENT_ID = "df2c744c-4da9-4b07-9de1-48fe3511dda3"

        /**
         * Mise en place d'une condition temporaire :
         * vérifier que les adresses email du souscripteur et du co-souscripteur sont différents
         */
        if (data[VOISIN_CO_SUBSCRIBER_EMAIL_ELEMENT_ID]) {
            const formDatasJSON = localStorage.getItem('form');

            if (formDatasJSON != null) {
                const formDatasSaved = JSON.parse(formDatasJSON)
                if (
                    formDatasSaved[VOISIN_SUBSCRIBER_EMAIL_ELEMENT_ID]
                    && formDatasSaved[VOISIN_SUBSCRIBER_EMAIL_ELEMENT_ID] === data[VOISIN_CO_SUBSCRIBER_EMAIL_ELEMENT_ID]
                ) {
                    hasErrors = true
                    setError(VOISIN_CO_SUBSCRIBER_EMAIL_ELEMENT_ID, {
                        type: "manual",
                        message: t('element.error.mail-must-be-different-from-co-sub')
                    })
                }
            }
        }

        /**
         * Mise en place d'une condition temporaire :
         * vérifier que les adresses email du souscripteur et du conjoint sont différents
         */
        if (data[VOISIN_SUBSCRIBER_SPOUSE_EMAIL_ELEMENT_ID]) {
            const formDatasJSON = localStorage.getItem('form');

            if (formDatasJSON != null) {
                const formDatasSaved = JSON.parse(formDatasJSON)
                if (
                    formDatasSaved[VOISIN_SUBSCRIBER_EMAIL_ELEMENT_ID]
                    && formDatasSaved[VOISIN_SUBSCRIBER_EMAIL_ELEMENT_ID] === data[VOISIN_SUBSCRIBER_SPOUSE_EMAIL_ELEMENT_ID]
                ) {
                    hasErrors = true
                    setError(VOISIN_SUBSCRIBER_SPOUSE_EMAIL_ELEMENT_ID, {
                        type: "manual",
                        message: t('element.error.mail-must-be-different-from-partner')
                    })
                }
            }
        }


        // .................................................................................................... Sogenial

        const SOGENIAL_SUBSCRIBER_EMAIL_ELEMENT_ID = "82c1b40e-a1b4-42d4-afdf-c8f8a9ebfb13"
        const SOGENIAL_CO_SUBSCRIBER_EMAIL_ELEMENT_ID = "49a0cc3e-1c26-40ab-8af8-427e16625a65"
        const SOGENIAL_SUBSCRIBER_COUNTERPART_EMAIL_ELEMENT_ID = "6c8aa3db-dfab-48a4-87aa-c8bc204704b4"

        /**
         * Mise en place d'une condition temporaire :
         * vérifier que les adresses email du souscripteur et du co-souscripteur sont différents
         */
        if (data[SOGENIAL_CO_SUBSCRIBER_EMAIL_ELEMENT_ID]) {
            const formDatasJSON = localStorage.getItem('form');

            if (formDatasJSON != null) {
                const formDatasSaved = JSON.parse(formDatasJSON)
                if (
                    formDatasSaved[SOGENIAL_SUBSCRIBER_EMAIL_ELEMENT_ID]
                    && formDatasSaved[SOGENIAL_SUBSCRIBER_EMAIL_ELEMENT_ID] === data[SOGENIAL_CO_SUBSCRIBER_EMAIL_ELEMENT_ID]
                ) {
                    hasErrors = true
                    setError(SOGENIAL_CO_SUBSCRIBER_EMAIL_ELEMENT_ID, {
                        type: "manual",
                        message: t('element.error.mail-must-be-different-from-co-sub')
                    })
                }
            }
        }

        /**
         * Mise en place d'une condition temporaire :
         * vérifier que les adresses email du souscripteur et de son contre-partie sont différents
         */
        if (data[SOGENIAL_SUBSCRIBER_COUNTERPART_EMAIL_ELEMENT_ID]) {
            const formDatasJSON = localStorage.getItem('form');

            if (formDatasJSON != null) {
                const formDatasSaved = JSON.parse(formDatasJSON)
                if (
                    formDatasSaved[SOGENIAL_SUBSCRIBER_EMAIL_ELEMENT_ID]
                    && formDatasSaved[SOGENIAL_SUBSCRIBER_EMAIL_ELEMENT_ID] === data[SOGENIAL_SUBSCRIBER_COUNTERPART_EMAIL_ELEMENT_ID]
                ) {
                    hasErrors = true
                    setError(SOGENIAL_SUBSCRIBER_COUNTERPART_EMAIL_ELEMENT_ID, {
                        type: "manual",
                        message: t('element.error.mail-must-be-different-from-partner')
                    })
                }
            }
        }

        /**
         * Mise en place d'une condition temporaire :
         * vérifier que la somme des valeurs saisies (inhérentes à cet ensemble d'éléments statiques) vaux 100
         */
        const mockedPercentageGroupElementsIds: Array<string> = [
            '6cefd0ca-57b0-4bc5-98a8-63b1e79e0867',
            '507df8da-3685-4a95-8d43-54b8893f698c',
            '43d3ea2a-c452-4367-bd27-9c0e8908113d',
            '720e5818-c62d-4c76-99a0-41bc1f5330ad',
            '17ea0440-77d8-4eb0-9b4e-fcf26ecedbdf',
        ]
        const mockedPercentageGroupElementsValues = mockedPercentageGroupElementsIds.map(
            (elementId) => data[elementId] ?? undefined
        )
        if (!mockedPercentageGroupElementsValues.includes(undefined)) {
            // 👇️ using <number> generic to set return type
            const total = mockedPercentageGroupElementsValues.reduce<number>((accumulator, current) => {
                return Number(accumulator) + Number(current);
            }, 0);

            if (total !== 100) {
                hasErrors = true
                const errorMessage = t('element.error.percentage-range-group-sum', {min: 100, max: 100});
                mockedPercentageGroupElementsIds.map(
                    (elementId) => setError(elementId.toString(), {type: 'manual', message: errorMessage})
                )
            }
        }

        /**
         * Mise en place d'une condition permanente :
         * vérifier que la somme des valeurs saisies (inhérentes à cet ensemble d'éléments dynamiques) vaux 100
         */
        for (const percentageGroupElement of getPercentageGroupElements(elements ?? [])) {
            const percentageInputs = getPercentageInputsByPercentageGroup(elements ?? [], percentageGroupElement);
            const sum = Number(getPercentageInputsSum(percentageInputs, data));


            if ( !percentageGroupElement.attributes?.min
                ||
                 !percentageGroupElement.attributes?.max
                ||
                !(Number(percentageGroupElement.attributes.min) >= sum)
                ||
                !(Number(percentageGroupElement.attributes.max) <= sum)
            ) {
                hasErrors = true;
                const errorMessage = percentageGroupElement.attributes.errorMessage || t('element.error.percentage-range-group-sum', {
                    min: percentageGroupElement.attributes.min,
                    max: percentageGroupElement.attributes.max
                });
                setError(percentageGroupElement.id, {type: 'manual', message: errorMessage});
            }
        }

        const fetchFolderGateway = new FetchFolderGateway();
        const folderResponse: any = await fetchFolderGateway.getFolder(String(storage.getFolderId()));

        switch (block.id) {
            case VOISIN_BLOCK_MISSION_VALIDATION:
                switch (folderResponse.dossier.status) {
                    case EFolderStatus.Ongoing:
                    case EFolderStatus.Adequacy_report_validated:
                        break;
                    case EFolderStatus.Mission_validated:
                    case EFolderStatus.Adequacy_report_validation:
                        hasErrors = true;
                        setGenericError(t('folder.warning.you-have-to-consult-email-to-check-continue-subscribing'));
                        break;
                    default:
                        hasErrors = true;
                        setGenericError(t('folder.warning.you-have-to-sign-to-finalize-subscribing'));
                }
                break;
            case VOISIN_BLOCK_SIGNATURE:
                switch (folderResponse.dossier.status) {
                    case EFolderStatus.Ongoing:
                    case EFolderStatus.Adequacy_report_validated:
                    case EFolderStatus.Signed:
                        break;
                    default:
                        hasErrors = true;
                        setGenericError(t('folder.warning.you-have-to-sign-to-finalize-subscribing'));
                }
                break;
            default:
                break;
        }

        if (hasErrors) {
            document.querySelector('.u-txt-color-error')?.scrollIntoView({behavior: 'smooth', block: "center"})
            setLoading(false)
            return false
        }
        const postUseCase = new PostUSeCase(new FormGateway(), fetchFolderGateway);
        const specialBlockIds: ICustomerElementIds = {
            'swisslife-am': '1e309a4f-0b2f-47b9-a835-55b681d2c040'
        }

        const getRefential = new GetReferential(new ReferentialGateway());
        const referential = await getRefential.execute(null, block.id)
        const customer = envVariable('REACT_APP_CUSTOMER')
        const formGateway = new FormGateway()
        const productCode: string|null = formGateway.getValueForElementId(referential?.productId ?? '') ?? ''
        if (!productCode) {
            console.log('form not configured properly from database')
        }
        const signBlockId: string | null = specialBlockIds[customer] ?? ''
        try {
            // Don't POST data if Role is back_office_user and just pass to next block
            if (!role || !roleBackOfficeList.includes(role) || storage.getFormId() === storage.getBackOfficeFormId()) {
                await postUseCase.execute(data, block.id, productCode)
                if (signBlockId !== null && block.id === signBlockId) {
                    gtmTracker({
                        eventName: envVariable('REACT_APP_GTM_EVENT_SIGN_ID')
                    })
                }
            }
            e.target.closest('.box-elevations').classList.add('box-elevations--closed')

            setTimeout(() => {
                if (storeBlocksVisible === null || storeBlocksVisibleLength === null) {
                    return
                }

                const blockCompleteBeforeModifyBlock = storeBlocksVisible.findIndex(blockFromStore => blockFromStore.id === block.id)
                const progressPourcentage = ((blockCompleteBeforeModifyBlock + 1) / storeBlocksVisibleLength) * 100

                dispatch(updateBlock({'id': block.id, 'state': 'complete', 'stateNext': 'progress'}))
                dispatch(updateProgressValueById({'id': pageId, 'progress': progressPourcentage}))
                dispatch(setTimestamp({'timestamp': Date.now()}))

                if (progressPourcentage === 100 && storeWizardStepNextPageId === null) {
                    history.push(`${envVariable('REACT_APP_REDIRECT_URL')}`);
                }
                if (progressPourcentage === 100 && storeWizardStepNextPageId) {
                    dispatch(updateNextPageId({'id': storeWizardStepNextPageId}))
                    history.push(`/form/${storeWizardStepNextPageId}`)
                }
            }, 800)
        } catch (e) {

            const folderResponse: any = await fetchFolderGateway.getFolder(String(storage.getFolderId()));
            setLoading(false);
            if (folderResponse.dossier.status === EFolderStatus.Mission_validation) {
                setGenericError(t('folder.warning.you-have-to-sign-to-finalize-subscribing'));
            } else if (e.response.data?.errors?.length > 0 && e.response.data?.errors[0].message && e.response.data?.errors[0].message) {
                setGenericError(e.response.data.errors[0].message)
            } else {
                setGenericError(t('error.technical-error'))
            }
        }
    };

    /**
     * Manage Block Errors Event
     * @param errors
     */
    const onError = (
        errors
    ) => {
        const firstKey = Object.keys(errors)[0]

        if ((errors[firstKey].ref)?.type === 'hidden' && !(errors[firstKey].ref).getAttribute("data-skip-error")) {
            document.querySelector('.errors-custom-generated-for-hidden')?.remove()
            const createErrorElement = document.createElement('span')
            createErrorElement.classList.add('help', 'u-txt-color-error')
            createErrorElement.innerText = errors[firstKey].message;
            const createErrorElementWrapper = document.createElement('div')
            createErrorElementWrapper.classList.add('col-md-12', 'errors-custom-generated-for-hidden')
            createErrorElementWrapper.appendChild(createErrorElement);
            ((errors[firstKey].ref).parentNode).appendChild(createErrorElementWrapper)
        }

        if (undefined != errors[firstKey].ref && errors[firstKey].ref?.scrollIntoView != undefined) {
            (errors[firstKey].ref).parentNode.scrollIntoView({behavior: 'smooth', block: "center"})
        } else {
            const reactSelectElementDiv = document.getElementById('form-select-' + (errors[firstKey].ref?.name ?? ''));
            if (reactSelectElementDiv) {
                reactSelectElementDiv.scrollIntoView({behavior: 'smooth', block: 'center'});
            } else {
                document.querySelector('.u-txt-color-error')?.scrollIntoView({behavior: 'smooth', block: "center"});
            }
        }

        setLoading(false)
    };

    const downloadAllDocuments = () => {
        if(isDownloading)
            return
        setDownloading(true)
        const fetchDocumentGateway = new FetchDocumentGateway()
        fetchDocumentGateway.getGeneratedDocumentsAsArchive(block.id).then(
            blob => {
                if(blob) {
                    const url = window.URL.createObjectURL(blob);
                    const anchorElement = document.createElement("a");
                    anchorElement.href = url;
                    anchorElement.setAttribute("download", "dossier-" + String(storage.getFolderId()) +".zip");
                    anchorElement.target = "_blank";
                    document.body.appendChild(anchorElement);
                    anchorElement.click();
                    anchorElement?.parentNode?.removeChild(anchorElement);
                }
            }
        ).finally(() =>
            setDownloading(false)
        )

    }

    const previewADocument = (generatedDoc: GeneratedDocument) => {
        if(isInitDocPreview)
            return
        setInitDocPreview(true)
        const fetchDocumentGateway = new FetchDocumentGateway()
        fetchDocumentGateway.getGeneratedDocumentById(generatedDoc.id).then(
            blob => {
                if(blob) {
                    setContentModal(buildModalContent(generatedDoc, blob))
                    setDocumentName(generatedDoc.filename)
                }
            }
        ).finally(() =>
            setInitDocPreview(false)
        )
    }

    const buildModalContent = (generatedDoc: GeneratedDocument, blob:any) => {
        toggleDocumentPreview()
        const url = window.URL.createObjectURL(blob);
        return (
            <div key={generatedDoc.id} className="pj-modal-content-center container-iframe">
            <iframe src={url} className="responsive-iframe"/>
            </div>
        )
    }

    const isDocumentPreviewModeEnabled = () => {
        return envVariable('REACT_APP_DOC_PREVIEW_MODE')
    }

    const childElement: (parentElement: Element) => Element | undefined = (
        parentElement
    ) => {
        const childElementId: string = parentElement.attributes?.childElementId ?? ''
        const childElement: Element | undefined = elements?.find(
            (element) => element.id === childElementId
        )
        return childElement
    }

    const childrenElements: (parentElement: Element) => (Element | undefined)[] | undefined = (
        parentElement
    ) => {
        const childrenElementsIds: string[] = parentElement.attributes?.childrenElementsIds ?? []
        const childrenElements = elements && childrenElementsIds.map(
            (elementId) => elements.find((element) => element.id === elementId)
        )
        return childrenElements?.filter(element => element !== undefined)
    }

    const multiChildParentsElements: Element[] | undefined = elements?.filter(
        (element) => Array.isArray(element.attributes?.childrenElementsIds)
    )

    const uniqueChildParentsElements: Element[] | undefined = elements?.filter(
        (element) => element.attributes?.childElementId
    )

    const multiChildParentsChildrenElementsIds: (string | undefined)[] | undefined = multiChildParentsElements?.map(
        (parentElement) => parentElement.attributes?.childrenElementsIds
    ).flat()

    const uniqueChildParentsChildrenElementsIds: (string | undefined)[] | undefined = uniqueChildParentsElements?.map(
        (parentElement) => parentElement.attributes?.childElementId
    ).flat()

    const childrenElementsIds = multiChildParentsChildrenElementsIds?.concat(uniqueChildParentsChildrenElementsIds)

    const parentsOnlyElements: Element[] | undefined = elements?.filter(
        (element) => childrenElementsIds?.find(
            (childElementId) => childElementId === element.id
        ) === undefined
    )

    const storeFirstPage = useAppSelector(state =>  state.wizzardStep.firstPage)

    const btnBackToPreviousBlock =
        (storeFirstPage?.id !== pageId && block.rank === 1)
        &&  (
            <button type="button" onClick={() => {
                dispatch(isBack({'back': true}))
                dispatch(updateProgressValueByIdGoBack({'id': null}))
                dispatch(updateNextPageIdAndCurrentPageIddByGoBack({'id': pageId}))
                dispatch(updateNextPageId({'id': storeWizardStepPrevPageId}))
                history.push(`/form/${storeWizardStepPrevPageId}`)
            }} className="button button-primary button--large u-mxAuto">
                {t('button.back-to-prev-block')}
            </button>
        )

    const previewDocWidgets = isDocumentPreviewModeEnabled() && documents && documents.map((document, index) =>
      <div key={index}
        className="button-preview"
        onClick={() => previewADocument(document)}
        title={document.filename}
      >
          <Preview />
          <div>{document.filename}</div>
      </div>
    )
    const getDownloadCaption = isDownloading ? t('button.download-in-progress') : t('button.download-document')

    const downloadDocWidget = isDocumentPreviewModeEnabled() && (
        <div className="button-preview button-preview--reverse"
            onClick={() => downloadAllDocuments()}
        >
            <IconFactory type="download"/>
            <div className={`u-prs`}>{getDownloadCaption}</div>
        </div>
    )

    const handleDuplicate = (documentDuplicate) => {
        setLoadingDuplicate(true)
        const folderId = localStorage.getItem("folder_id") || ''
        const folderGateway = new FetchFolderGateway()
        folderGateway.duplicateFolder(folderId, documentDuplicate)
            .then((folder) => {
                if (folder) {
                    storage.setFolderId(folder.id)
                    storage.setFormId(folder.form_id)
                    const getDetailAndStoreItUseCase = new GetDetailAndStoreItUseCase(new FetchFolderGateway(), new LocalStorageGateway())
              
                    getDetailAndStoreItUseCase.execute(folder.id).then((response) => {
                        if (response !== null) {
                            history.push('/form')
                        }
                    })
                }
                setLoadingDuplicate(false)
            })
            .catch((e) => {
                console.error(e)
                setLoadingDuplicate(false)
            })
    }

    const btnDuplicateFolder = () => {
        const role = localStorage.getItem('role') || '';
        if (!roleHideButtonDuplicate.includes(role) && isFolderDuplicationEnabled()) {
            return (
                <button type="button" onClick={toggleDuplicate} className="button button-primary button--large u-mxAuto">
                    {t('button.duplicate')}
                </button>
            )
        }
    }

    return (
        <>
            {isLoadingDuplicate && <PageLoader description={t('folder.modal-duplication.display-duplicate-loader')} />}
            {isDocumentPreviewModeEnabled() && (isInitDocPreview || isDocumentPreview) &&
                <Modal title={t('pj.modal-preview-title')}
                    description={documentName}
                    customClassName={`modal-lg`}
                    hide={toggleDocumentPreview}
                    loading={isInitDocPreview}>
                    {contentModal}
                </Modal>
            }

            {
                elements && formData &&
                <form onSubmit={handleSubmit(onSubmit, onError)}
                      className="form--grey-7"
                      ref={blockRef}
                      noValidate={true}
                >
                    <div className={'flex-container'}>
                        {
                            parentsOnlyElements?.map(
                                (element, index) => {
                                    return (
                                        <ElementFactory
                                            key={index}
                                            blockId={block.id}
                                            element={element}
                                            childElement={childElement(element)}
                                            childrenElements={childrenElements(element)}
                                            register={register}
                                            clearErrors={clearErrors}
                                            formData={formData}
                                            control={control}
                                            error={errors[element.id]}
                                            onChangeValue={handleChange}
                                            />
                                    )
                                }
                            )
                        }
                    </div>
                    <div className="flex-container center-md u-mtl u-relative">
                        {
                            genericError !== '' &&
                            <>
                                <p className="notification notification--error">{genericError}</p>
                            </>
                        }
                        {
                            errors.files &&
                            <>
                                <p className="notification notification--error u-pointer"
                                   onClick={() => clearErrors('files')}
                                >
                                    {errors.files.message}
                                </p>
                            </>
                        }
                        {
                            ![
                                VOISIN_BLOCK_WELCOME,
                                VOISIN_BLOCK_MISSION_VALIDATION,
                                VOISIN_BLOCK_SIGNATURE,
                                VOISIN_BLOCK_FINAL,
                            ].includes(block.id) &&
                            'signature' != block.slug &&
                            'finalisation' != block.slug &&
                            undefined == errors.files &&
                            <>
                                <div className="col-md-12">
                                    {
                                        documents && documents.length > 0 && <>
                                            <div className="row no-compensation-xs u-pys preview-doc-widgets">
                                                { previewDocWidgets }
                                            </div>
                                            <div className="row no-compensation-xs u-pys preview-doc-widgets">
                                                { downloadDocWidget }
                                            </div>
                                        </>
                                    }
                                    {
                                        !isLoading &&
                                            <div className="row u-mtm">
                                                { btnBackToPreviousBlock }
                                                <button type="submit" className="button button-primary button--large u-mxAuto">
                                                    {t('button.keep-on')}
                                                </button>
                                            </div>
                                    }
                                    {
                                        isLoading && <Loader/>
                                    }
                                </div>
                                <div className="illustration illustration--profile"
                                     dangerouslySetInnerHTML={{__html: block.icon}}
                                >
                                </div>
                            </>
                        }
                        {
                            ![
                                VOISIN_BLOCK_WELCOME,
                                VOISIN_BLOCK_MISSION_VALIDATION,
                                VOISIN_BLOCK_SIGNATURE,
                                VOISIN_BLOCK_FINAL,
                            ].includes(block.id) &&
                            'signature' != block.slug &&
                            'finalisation' == block.slug &&
                            undefined == errors.files &&
                            <>
                                <div className="col-md-12">
                                    {
                                        documents && documents.length > 0 && <>
                                            <div className="row no-compensation-xs u-pys preview-doc-widgets">
                                                { previewDocWidgets }
                                            </div>
                                            <div className="row no-compensation-xs u-pys preview-doc-widgets">
                                                { downloadDocWidget }
                                            </div>
                                        </>
                                    }
                                    {
                                        !isLoading &&
                                            <div className="row u-mtm">
                                                { btnDuplicateFolder() }
                                                <button type="submit" className="button button-primary button--large u-mxAuto">
                                                    {t('button.finish')}
                                                </button>
                                            </div>
                                    }
                                    {
                                        isLoading && <Loader/>
                                    }
                                </div>
                                <div className="illustration illustration--profile"
                                     dangerouslySetInnerHTML={{__html: block.icon}}
                                >
                                </div>
                                {isShowingDuplicate && <FolderModal title={t('folder.modal-duplication.title')}
                                    description={t('folder.modal-duplication.description')}
                                    hide={toggleDuplicate}
                                    customClassName={`modal`}
                                    forceClose={false}>
                                    <div className="modal-container-buttons">
                                        <button type="button"
                                            className="button-reset button-primary--outline button--medium button--width-fit"
                                            onClick={() => handleDuplicate(false)}
                                        >
                                            {t('folder.modal-duplication.no-i-decline')}
                                        </button>
                                        <button type="button"
                                            className="button-reset button-primary--outline button--medium button--width-fit"
                                            onClick={() => handleDuplicate(true)}
                                        >
                                            {t('folder.modal-duplication.yes-i-duplicate-documents')}
                                        </button>
                                    </div>
                                </FolderModal>}
                            </>
                        }
                        {
                            ([
                                VOISIN_BLOCK_MISSION_VALIDATION,
                                VOISIN_BLOCK_SIGNATURE,
                            ].includes(block.id) ||
                            'signature' == block.slug) &&
                            <>
                                <div className="col-md-12">
                                    {
                                        documents && documents.length > 0 && <>
                                          <div className="row no-compensation-xs u-pys preview-doc-widgets">
                                              {previewDocWidgets}
                                          </div>
                                          <div className="row no-compensation-xs u-pys preview-doc-widgets preview-doc-widgets--download">
                                              {downloadDocWidget}
                                          </div>
                                      </>
                                    }
                                    {
                                      !isLoading &&
                                      <div className="row u-mtm">
                                          {btnBackToPreviousBlock}
                                          <button type="submit"
                                                  className="button button-primary button--large u-mxAuto">
                                              {t('button.sign')}
                                          </button>
                                      </div>
                                    }
                                    {
                                      isLoading && <Loader/>
                                    }
                                </div>
                                <div className="illustration illustration--profile"
                                     dangerouslySetInnerHTML={{__html: block.icon}}
                                >
                                </div>
                            </>
                        }
                        {
                            block.id === 'e497fd89-4b40-4e99-9e7a-b2f63ec9d669' &&
                            undefined == errors.files &&
                            <>
                                <div className="illustration" dangerouslySetInnerHTML={{__html: block.icon}}>
                                </div>
                            </>
                        }
                        {
                            block.id === 'c2fc93ff-1e3e-499e-a060-a111668601b5' &&
                            undefined === errors.files &&
                            <>
                                <div className="col-md-12">
                                    {(!isLoading &&
                                        <button type="submit"
                                                className="button button-primary button--large u-mxAuto"
                                        >
                                            {t('button.begin')}
                                        </button>
                                    )}
                                    {(isLoading &&
                                        <Loader/>
                                    )}
                                </div>
                                <div className="illustration illustration--profile"
                                     dangerouslySetInnerHTML={{__html: block.icon}}>
                                </div>
                            </>
                        }
                    </div>
                </form>
            }
        </>
    )
}

const getPercentageGroupElements = (elements: Element[]): Element[] => {
    return elements.filter(element => element.type === EElementType.GROUP_PERCENTAGE_INPUT_RANGE);
}

const getPercentageInputsByPercentageGroup = (elements: Element[], percentageRangeGroup: Element): Element [] => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return elements.filter(element => percentageRangeGroup.attributes?.childrenElementsIds.includes(element.id));
}

const getPercentageInputsValue = (percentageInputs: Element[], data: Record<string, any>) => {
    const percentageInputsWithCheckboxCheckedOrWithoutCheckbox = percentageInputs.filter(
        percentageInput => percentageInput.attributes?.childElementId ?
            data[percentageInput.attributes.childElementId] === true :
            true
    );
    return percentageInputsWithCheckboxCheckedOrWithoutCheckbox.map(
        percentageInput => Number.isInteger(parseInt(data[percentageInput.id])) ?
            parseInt(data[percentageInput.id]) :
            0
    );
}

const getPercentageInputsSum = (percentageInputs: Element[], data: Record<string, any>) => {
    return getPercentageInputsValue(percentageInputs, data).reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
    );
}

export default BlockFormComponent;
