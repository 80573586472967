import React, { FunctionComponent, useState, useMemo, useEffect } from 'react'
import parse from 'html-react-parser'
import { Popover } from 'react-tiny-popover'

import Button from '../button/Button'
import MarkRounded from '../svg/MarkRounded'
import Block from '../../../domain/Block/Block'
import BlockFormComponent from './BlockFormComponent'
import BlockPreviewComponent from './BlockPreviewComponent'
import { useAppDispatch, useAppSelector } from '../../../store/hook'
import ValidateConditionUseCase from '../../../domain/Condition/UseCase/ValidateConditionUseCase'
import FormGateway from '../../../gateway/Form/FormGateway'
import { isVisibleConditionBlock } from '../../util/validateCondition'
import { updateBlock } from '../../../store/block/blocks'

type Props = {
  pageId: string
  blockId: string
}

const CustomComponent = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ onMouseEnter, onMouseLeave, children }, ref) => (
    <div ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
    </div>
  )
)

const BlockComponent: FunctionComponent<Props> = ({ pageId, blockId }) => {
  const dispatch = useAppDispatch()
  const blocks = useAppSelector((state) => state.blocks.all)
  const storeIsBack = useAppSelector((state) => state.wizzardStep.isBack)

  const [block, setBlock] = useState<Block | null>(null)
  const [validationState, setValidationState] = useState<boolean>(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)

  const validateConditionUseCase = useMemo(() => new ValidateConditionUseCase(new FormGateway()), [])

  const visibleBlocks = useMemo(() => {
    const datas = validateConditionUseCase.formGatewayInterface.getCurrentValueForFormId()

    return blocks.filter((block) => {
      if (block.condition) {
        return isVisibleConditionBlock(block, validateConditionUseCase.executeWithDatas(block.condition, datas))
      }
      return block.visible
    })
  }, [blocks, validateConditionUseCase])

  useEffect(() => {
    if (!storeIsBack || visibleBlocks.length === 0) return

    visibleBlocks.forEach((b, index) => {
      dispatch(
        updateBlock({
          id: b.id,
          state: index === visibleBlocks.length - 1 ? 'progress' : 'complete',
          stateNext: 'waiting',
        })
      )
    })
  }, [storeIsBack, visibleBlocks, dispatch])

  useEffect(() => {
    const blockFromStore = blocks.find((block) => block.id === blockId) || null
    setBlock(blockFromStore)

    if (blockFromStore?.condition) {
      setValidationState(validateConditionUseCase.execute(blockFromStore.condition))
    }
  }, [blocks, blockId, validateConditionUseCase])

  const isVisible = block ? isVisibleConditionBlock(block, validationState) : false

  return (
    <>
      {block && (
        <>
          {(['complete', 'validate'].includes(block.state) && isVisible) && (
            <BlockPreviewComponent block={block} state={block.state} pageId={pageId} role="" />
          )}

          {(block.state === 'waiting' && isVisible) && (
            <BlockPreviewComponent pageId={pageId} block={block} state="disable" role="" />
          )}

          {(!['complete', 'waiting'].includes(block.state) && isVisible) && (
            <div className={`${block.cssClasses || ''} box-elevations box-elevations--base box-elevations--mb u-mxAuto`}>
              <h2 className="help-wrapper u-mbs">
                {block.title}
                {block.help && (
                  <Popover
                    isOpen={isPopoverOpen}
                    positions={['bottom']}
                    align="end"
                    padding={10}
                    onClickOutside={() => setIsPopoverOpen(false)}
                    content={<div className="box-helpers">{parse(block.help)}</div>}
                  >
                    <CustomComponent
                      onMouseEnter={() => setIsPopoverOpen(true)}
                      onMouseLeave={() => setIsPopoverOpen(false)}
                    >
                      <Button classes="help__button" label="" position="alone" icon={<MarkRounded />} />
                    </CustomComponent>
                  </Popover>
                )}
              </h2>
              <BlockFormComponent pageId={pageId} block={block} />
            </div>
          )}
        </>
      )}
    </>
  )
}

export default BlockComponent
