import React, { FunctionComponent, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Page from '../../../domain/Page/Page'
import WizardStepComponent from './WizardStepComponent'
import formatNumber from '../../util/formatNumber'

import { useAppDispatch, useAppSelector } from '../../../store/hook'
import {
  updateNextPageId,
  updateProgressValueByIdGoBack
} from '../../../store/wizzard/wizzardStep'

type Props = {
  pages: Page[],
  currentPageId: string,
}

const WizardComponent: FunctionComponent<Props> = ({ currentPageId }) => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const storePages = useAppSelector(state => state.wizzardStep.pages)
  const storeWizardStepCurrentPage = useAppSelector(state => state.wizzardStep.currentPageId)
  const storeWizardStepNextPageId = useAppSelector(state => state.wizzardStep.nextPageId)
  const storeIsBack = useAppSelector(state => state.wizzardStep.isBack)
  const blocksVisibleLength = useAppSelector(state => state.blocks.blocksVisibleLength)

  const [pages, setPages] = useState<Page[] | null>(
    typeof storePages === 'object' ? storePages : JSON.parse(storePages)
  )

  useEffect(() => {
    setPages(typeof storePages === 'object' ? storePages : JSON.parse(storePages))
  }, [storePages])

  const currentPageIndex = pages?.findIndex((p) => currentPageId === p.id)

  useEffect(() => {
    if (currentPageId && storeWizardStepCurrentPage !== currentPageId) {
      dispatch(updateNextPageId({ id: currentPageId }))
    }
  }, [currentPageId, storeWizardStepCurrentPage, dispatch])

  useEffect(() => {
    if (blocksVisibleLength === 0) {
      dispatch(updateNextPageId({ id: storeWizardStepNextPageId }))
      history.push(`/form/${storeWizardStepNextPageId}`)
    }
  }, [blocksVisibleLength, storeWizardStepNextPageId, dispatch, history])

  useEffect(() => {
    if (storeIsBack && storeWizardStepCurrentPage) {
      const currentPage = pages?.find(p => p.id === storeWizardStepCurrentPage)
      if (currentPage) {
        const progressPourcentage = blocksVisibleLength === 1 ? 0 : ((blocksVisibleLength - 1) / blocksVisibleLength) * 100
        dispatch(updateProgressValueByIdGoBack({ id: storeWizardStepCurrentPage, progress: progressPourcentage }))
      }
    }
  }, [storeIsBack, storeWizardStepCurrentPage, blocksVisibleLength, pages, dispatch])

  return (
    pages && (
      <div className="wizzard u-mxAuto">
        <div className="wizzard-inner">
          {pages.map((page, index) => (
            <WizardStepComponent
              key={index}
              page={page}
              currentPageId={currentPageId}
              number={formatNumber(index + 1, 2)}
              progress={currentPageIndex !== undefined && index < currentPageIndex ? 100 : page.progress}
            />
          ))}
        </div>
      </div>
    )
  )
}

export default WizardComponent
