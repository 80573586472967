import FolderGatewayInterface from '../../domain/Folder/FolderGatewayInterface'
import PairingInterface from '../../domain/Folder/PairingInterface'

export default class GetFolderPairingUseCase
{
  constructor(private readonly folderGateway: FolderGatewayInterface) {}

  async handle(dossierId: string): Promise<PairingInterface|null> {
    return this.folderGateway.getPairing(dossierId)
      .then((response) => {
        return response
      })
  }
}
