import React, { FunctionComponent } from 'react';

const DownloadDoc: FunctionComponent = () => {
    return (
        <svg height="20px" version="1.1" viewBox="0 0 24 16" width="20px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title /><desc /><defs /><g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
                <g fill="#000000" id="Core" transform="translate(-504.000000, -88.000000)">
                    <g id="cloud-download" transform="translate(504.000000, 88.000000)">
                        <path d="M19.4,6 C18.7,2.6 15.7,0 12,0 C9.1,0 6.6,1.6 5.4,4 C2.3,4.4 0,6.9 0,10 C0,13.3 2.7,16 6,16 L19,16 C21.8,16 24,13.8 24,11 C24,8.4 21.9,6.2 19.4,6 L19.4,6 Z M17,9 L12,14 L7,9 L10,9 L10,5 L14,5 L14,9 L17,9 L17,9 Z" id="Shape" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default DownloadDoc;
