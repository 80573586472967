import React, {FunctionComponent} from 'react';
import Button from '../../button/Button';

// Redux
import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {modifyBlock} from '../../../../store/block/blocks';
import {updateProgressValueById} from '../../../../store/wizzard/wizzardStep';
import {useTranslation} from 'react-i18next';

type Props = {
  blockId: string,
  pageId: string
};

const ModifyComponent: FunctionComponent<Props> = ({blockId, pageId}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const storeBlocksVisible = useAppSelector(state =>  state.blocks.blocksVisible)
  const storeBlocksVisibleLength = useAppSelector(state =>  state.blocks.blocksVisibleLength)

  function modify () {
    if (storeBlocksVisible && storeBlocksVisibleLength) {
      const blockCompleteBeforeModifyBlock = storeBlocksVisible.findIndex(block => block.id === blockId)
      const progressPourcentage = ((blockCompleteBeforeModifyBlock) / storeBlocksVisibleLength) * 100
      dispatch(modifyBlock({'id': blockId}))
      dispatch(updateProgressValueById({'id': pageId, 'progress': progressPourcentage}))
    }
  }

  return (
    <>
      <div className="card__second"></div>
      <div className="card__third card__third--modify">
        <Button classes="button button-primary--outline button--medium button--width-fit" label={t('button.modify')} onClick={modify}/>
      </div>
    </>
  );
}

export default ModifyComponent;
