import PairingInterface from "./PairingInterface";

class Pairing implements PairingInterface {
  pairing: {[scope: string]: string|null}
  forceMandate: boolean

  constructor(
    pairing: {[scope: string]: string|null},
    forceMandate: boolean
  ) {
    this.pairing = pairing
    this.forceMandate = forceMandate
  }
}

export {
  Pairing
}
