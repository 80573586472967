import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import ReceiptViewGateway from '../../../gateway/Receipt/ReceiptViewGateway'
import {ReceiptView} from '../../../domain/Receipt/ReceiptView'
import {IntegrationStateHistoryInterface} from '../../../domain/IntegrationStateHistory/IntegrationStateHistory'
import GetIntegrationStateHistoryUseCase from '../../../useCase/Folder/GetIntegrationStateHistoryUseCase'
import IntegrationStateHistoryGateway from '../../../gateway/Folder/IntegrationStateHistoryGateway'
import {Tab, Tabs} from '../tab/Tab'
import IntegrationStateHistoryBlock from '../integrationState/IntegrationStateHistoryBlock'
import ReceiptsValidation from '../integrationState/ReceiptsValidation'

// Styles
import '../../../assets/styles/components/_validation.scss'
import RetrieveCustomerInfoRecallUseCase from '../../../useCase/Folder/RetrieveCustomerInfoRecallUseCase'
import CustomerInfoRecallGateway from '../../../gateway/Folder/CustomerInfoRecallGateway'
import Pairing from '../integrationState/Pairing'
import ViewDossierGateway from '../../../gateway/ViewDossier/ViewDossierGateway'
import RetrieveViewDossierUseCase from '../../../useCase/Folder/RetrieveViewDossierUseCase'
import PresentationDossier from '../integrationState/PresentationDossier'
import {ViewPageInterface} from '../../../domain/ViewDossier/ViewPage'
import FetchFolderGateway from '../../../gateway/Folder/FetchFolderGateway'
import { SignedDocument } from '../../../domain/Docusign/SignedDocument'
import GetListSignedDocumentUseCase from '../../../useCase/Docusign/GetListSignedDocumentUseCase'
import FetchSignedDocumentGateway from '../../../gateway/Docusign/FetchSignedDocumentGateway'
import { FolderDetail } from '../../../domain/Folder/Folder'
import FolderDetails from '../folder/FolderComponent/FolderDetails'
import { SubscriberScopeEnum } from '../../../domain/CustomerInfoRecall/SubscriberScopeEnum'
import GetFolderPairingUseCase from '../../../useCase/Folder/GetFolderPairingUseCase'
import SearchCustomerInfoRecallUseCase from '../../../useCase/Folder/SearchCustomerInfoRecallUseCase'
import { PotentialCustomerInterface, SubscriberInterface } from '../../../domain/CustomerInfoRecall/CustomerInfoRecallGatewayInterface'

type Props = {
  dossierId: string
}

const BlockValidationSummaryComponent: FunctionComponent<Props> = ({dossierId}) => {
  const {t} = useTranslation()

  const [dossierSummary, setDossierSummary] = useState<FolderDetail | null>(null)
  const [documents, setDocuments] = useState<SignedDocument[] | null>([])
  const [receipts, setReceipts] = useState<{ [key: string]: ReceiptView[] } | null>(null)
  const [integrationStateHistory, setIntegrationStateHistory] = useState<IntegrationStateHistoryInterface[] | null>(null)
  const [subscribers, setSubscribers] = useState<{[scope: string]: SubscriberInterface} | null>(null)
  const [potentialCustomers, setPotentialCustomers] = useState<{[scope: string]:PotentialCustomerInterface[]} | null>(null)
  const [dossierPresentation, setDossierPresentation] = useState<ViewPageInterface[] | null>(null)
  const [state, setState] = useState<string | null>(null)
  const [folderState, setFolderState] = useState<string | null>(null)
  const [pairing, setPairing] = useState<{[scope: string]: string|null}>({})
  const [manualSelection, setManualSelection] = useState<{[scope: string]: PotentialCustomerInterface|null}>({np: null, usu: null})
  const [selectedScope, setSelectedScope] = useState<string>(SubscriberScopeEnum.NP)

  useEffect(() => {
    new FetchFolderGateway().getSummary(dossierId).then((response) => {
      setDossierSummary(response)
    })

    new FetchFolderGateway().retrieveState(dossierId).then((response) => {
      const state = t(`common.${response}`)
      setState(state)
      setFolderState(response)
    })

    const pairingUseCase = new GetFolderPairingUseCase(new FetchFolderGateway())
    pairingUseCase.handle(dossierId).then((response) => {
      if (response) {
        setPairing(response.pairing)
      }
    })

    new ReceiptViewGateway().retrieveList(dossierId).then((response) => {
      setReceipts(response)
    })

    new GetListSignedDocumentUseCase(new FetchSignedDocumentGateway()).execute(dossierId).then((response) => {
      setDocuments(response)
    })

    const getIntegrationStateHistoryUseCase = new GetIntegrationStateHistoryUseCase(new IntegrationStateHistoryGateway())
    getIntegrationStateHistoryUseCase.handle({dossierId: dossierId}).then((response) => {
      setIntegrationStateHistory(response)
    })

    const retrieveCustomerInfoRecallUseCase = new RetrieveCustomerInfoRecallUseCase(new CustomerInfoRecallGateway())
    retrieveCustomerInfoRecallUseCase.handle({dossierId: dossierId, scope:SubscriberScopeEnum.NP}).then((response) => {
      if (response !== null) {
        setSubscribers(response.subscribers)
        setPotentialCustomers(response.customers)
      }
    })

    const retrieveViewDossierUseCase = new RetrieveViewDossierUseCase(new ViewDossierGateway())
    retrieveViewDossierUseCase.handle({dossierId: dossierId}).then((response) => {
      if (response !== null) {
        setDossierPresentation(response)
      }
    })
    
  }, [dossierId])

  useEffect(() => {
    [SubscriberScopeEnum.NP, SubscriberScopeEnum.USU].forEach((scope: string) => {
      const pairedUsername = pairing[scope] ?? 'undefined';
      if ('undefined' !== pairedUsername) {
        const listedUser = potentialCustomers?.[scope]?.filter(customer => customer.username === pairedUsername);
        if (listedUser?.length === 0 || 'undefined' === typeof listedUser) {
          const searchCustomerInfoRecallUseCase = new SearchCustomerInfoRecallUseCase(new CustomerInfoRecallGateway())
          searchCustomerInfoRecallUseCase.handle(pairedUsername).then((response) => {
            if (response) {
              const newUserSelection = {}
              newUserSelection[scope] = response
              setManualSelection(manualSelection => ({...manualSelection, ...newUserSelection}))
            }
          })
        } else {
          const newUserSelection = {}
          newUserSelection[scope] = null
          setManualSelection(manualSelection => ({...manualSelection, ...newUserSelection}))
        }
      } else {
        if (!pairing[scope]) {
          const newUserSelection = {}
          newUserSelection[scope] = null
          setManualSelection(manualSelection => ({...manualSelection, ...newUserSelection}))
        }
      }
    })
  }, [pairing, potentialCustomers])

  const reloadHistory = (scope: string, username: string|null) => {
    const newPairing = {}
    newPairing[scope] = username
    setPairing(pairing => ({...pairing, ...newPairing}))
    const getIntegrationStateHistoryUseCase = new GetIntegrationStateHistoryUseCase(new IntegrationStateHistoryGateway())
    getIntegrationStateHistoryUseCase.handle({dossierId: dossierId}).then((response) => {
      setIntegrationStateHistory(response)
    })
  };

  return (
    <>
      <div className="box-elevations box-elevations--base box-elevations--mb u-mxAuto u-pxm u-pym">
        <h4 className={`u-mb0`}>
          { dossierSummary && <FolderDetails FolderDetail={dossierSummary} error={false} FolderStatuses={state ? [state]:[]} />}
        </h4>
      </div>
    <Tabs classes="wrap u-mxAuto">
      {dossierPresentation !== null &&
        <Tab key="folder" label={t('validation.presentation-dossier')}>
          <PresentationDossier dossierId={dossierId} viewPages={dossierPresentation} />
        </Tab>
      }
      {receipts !== null &&
        <Tab key="receipt" label={t('validation.receipts')}>
          <ReceiptsValidation dossierId={dossierId} receipts={receipts} documents={documents} />
        </Tab>
      }
      {subscribers !== null && (folderState === 'ongoing' || folderState === 'error') && 
        <Tab key="pairing" label={t('validation.pairing-unicia')}>
          <Pairing 
            dossierId={dossierId}
            dossierSummary={dossierSummary}
            subscribers={subscribers}
            potentialCustomers={potentialCustomers}
            setScope={setSelectedScope}
            scope={selectedScope}
            pairing={pairing}
            manualSelection={manualSelection}
            onModify={reloadHistory}
          />
        </Tab>
      }
      {integrationStateHistory !== null &&
        <Tab key="history" label={t('validation.actions')}>
          <IntegrationStateHistoryBlock integrationStateHistory={integrationStateHistory} />
        </Tab>
      }
    </Tabs>
    </>
  )
}

export default BlockValidationSummaryComponent
