import React, {Key, FunctionComponent, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {RouteComponentProps} from 'react-router-dom';

import HeaderLightComponent from '../component/header/HeaderLightComponent';
import FooterComponent from '../component/footer/FooterComponent';
import storage from '../util/storage';
import CreateUseCase from '../../useCase/Folder/CreateUseCase';
import FetchFolderGateway from '../../gateway/Folder/FetchFolderGateway';

import SignedFiles from '../component/svg/SignedFiles';
import SignatureFiles from '../component/svg/SignatureFiles';
import CurrentCases from '../component/svg/CurrentCases';
import AbandonedFiles from '../component/svg/AbandonedFiles';
import SynchroPending from '../component/svg/SynchroPending'
import SynchroSuccess from '../component/svg/SynchroSuccess'
import ToValidate from '../component/svg/ToValidate'
import SynchroError from '../component/svg/SynchroError'
import SynchroRefused from '../component/svg/SynchroRefused'

import '../../assets/styles/components/_dashboard.scss'
import envVariable, { isManualRegistrationEnabled } from '../util/envVariable'
import { ERole, roleBackOfficeList } from '../../domain/User/Erole';
import {Tab, Tabs} from '../component/tab/Tab'

type Params = {
  currentTab: string
};

const Dashboard: FunctionComponent<RouteComponentProps<Params>> = ({match}) => {
  const {t} = useTranslation()
  const history = useHistory()

  const [role] = useState<string>(localStorage.getItem('role') ?? '');
  const [signedFiles, setSignedFiles] = useState<number>(0);
  const [signatureFiles, setSignatureFiles] = useState<number>(0);
  const [currentCases, setCurrentCases] = useState<number>(0);
  const [abandonedFiles, setAbandonedFiles] = useState<number>(0);
  const [signedToSync, setSignedToSync] = useState<number>(0);
  const [signedValidated, setSignedValidated] = useState<number>(0);
  const [signedSyncError, setSignedSyncError] = useState<number>(0);
  const [signedSync, setSignedSync] = useState<number>(0);
  const [signedSyncRefused, setSignedSyncRefused] = useState<number>(0);

  useEffect(() => {
    (new FetchFolderGateway()).retrieveNumberDossiersByState().then(numberDossiersByState => {
        if (null !== numberDossiersByState) {
          setSignedFiles(numberDossiersByState.signed)
          setSignatureFiles(numberDossiersByState.signature)
          setCurrentCases(numberDossiersByState.current)
          setAbandonedFiles(numberDossiersByState.abandoned)
          setSignedToSync(numberDossiersByState.signed_ongoing)
          setSignedValidated(numberDossiersByState.signed_validated)
          setSignedSyncError(numberDossiersByState.signed_error)
          setSignedSync(numberDossiersByState.signed_sync)
          setSignedSyncRefused(numberDossiersByState.signed_refused)
        }
      }
    )
  }, [])

  const createUseCase = new CreateUseCase(new FetchFolderGateway());

  function createFileWithoutClient() {
    createUseCase.execute(storage.getClientFormId(), null)
      .then(() => {
        history.push(`/form/`)
      })
  }

  function manualSubscriptionRegistration() {
    createUseCase.execute(storage.getBackOfficeFormId(), null)
      .then(() => {
        history.push(`/form/`)
      })
  }

  const userRole = localStorage.getItem('role');
  const isBackOfficeUser = (userRole && roleBackOfficeList.includes(userRole));
  const keyTab = match?.params?.currentTab !== undefined ? match.params.currentTab : 'gestion'
  
  const onTabChange = (index: number, key: Key|null) => {
    if (index === 0) {
      localStorage.setItem("previousPage", `/tableau-de-bord/gestion/`);
      history.push(`/tableau-de-bord/gestion/`, { from: `/tableau-de-bord/gestion/` })
    } else {
      localStorage.setItem("previousPage", `/tableau-de-bord/integration/`);
      history.push(`/tableau-de-bord/integration/`, { from: `/tableau-de-bord/integration/` })
    }
  };

  return <>
    <HeaderLightComponent />
    <>
      <div className="wrap u-mxAuto">
        <div className="flex-container">
          <div className="col-md-12">
            <h2 className="h3 help-wrapper u-mbs">{t('dashboard.title')}</h2>
          </div>
        </div>
      </div>
      {role === ERole.ManageDossier &&
        <Tabs active={keyTab === 'gestion' ? 0 : 1} onTabChange={onTabChange} classes={'wrap u-mxAuto'} tabClass='col-md-3 mb-0' tabButtonClass={'button-tab button-folder button-tab-white'}>
          [
          <Tab key={0} label={t('dashboard.tabs.manage')}>
            <div className="min-height-content">
              <div className="box-elevations box-elevations--base box-elevations--max-heigt-initial box-elevations--mb u-mxAuto br-tl-0">
                <div className="dashboard__filters">
                  <>
                    <Link to={`/dossier/signed-files`} className="dashboard__filter dashboard__filter__signed">
                      <div className="filter__icon"><SignedFiles color="var(--ink-1)"/></div>
                      <div className="filter__number">{signedFiles}</div>
                      <div className="filter__label" dangerouslySetInnerHTML={{__html: t('dashboard.signed-files')}}/>
                    </Link>
                    <Link to={`/dossier/signature-files`} className="dashboard__filter dashboard__filter__signature">
                      <div className="filter__icon"><SignatureFiles color="var(--ink-1)"/></div>
                      <div className="filter__number">{signatureFiles}</div>
                      <div className="filter__label" dangerouslySetInnerHTML={{__html: t('dashboard.signature-files')}}/>
                    </Link>
                    <Link to={`/dossier/current-cases`} className="dashboard__filter  dashboard__filter__current-cases">
                      <div className="filter__icon"><CurrentCases color="var(--ink-1)"/></div>
                      <div className="filter__number">{currentCases}</div>
                      <div className="filter__label" dangerouslySetInnerHTML={{__html: t('dashboard.current-cases')}}/>
                    </Link>
                    <Link to={`/dossier/abandoned-files`} className="dashboard__filter  dashboard__filter__abandonned-files">
                      <div className="filter__icon"><AbandonedFiles color="var(--ink-1)"/></div>
                      <div className="filter__number">{abandonedFiles}</div>
                      <div className="filter__label" dangerouslySetInnerHTML={{__html: t('dashboard.abandoned-files')}}/>
                    </Link>
                  </>
                </div>
              </div>
            </div>
          </Tab>
          ]
          [
          <Tab key={1} label={t('dashboard.tabs.integration')}>
            <>
              <div className="min-height-content">
                <div className="box-elevations box-elevations--base box-elevations--max-heigt-initial box-elevations--mb u-mxAuto">
                  <div className="flex-container heading-folder-cta">
                    {isManualRegistrationEnabled() &&
                      <>
                        <div className="col-md-12">
                          <div className="button__container">
                            <a href="#" onClick={() => manualSubscriptionRegistration()}
                              className="create-folder-prospect button button-primary button--large button--width-full">{t('folder.register-folder')}</a>
                          </div>
                        </div>
                      </>
                    }
                  </div>
                  <div className="dashboard__filters">
                    <Link to={`/manage-dossier/statut-integration/ongoing`} className="dashboard__filter dashboard__filter__ongoing">
                      <div className="filter__icon"><ToValidate color="var(--ink-1)" /></div>
                      <div className="filter__number">{signedToSync}</div>
                      <div className="filter__label" dangerouslySetInnerHTML={{ __html: t('dashboard.integration-state.ongoing') }} />
                    </Link>
                    <Link to={`/manage-dossier/statut-integration/validated`} className="dashboard__filter  dashboard__filter__validated">
                      <div className="filter__icon"><SynchroPending color="var(--ink-1)" /></div>
                      <div className="filter__number">{signedValidated}</div>
                      <div className="filter__label" dangerouslySetInnerHTML={{ __html: t('dashboard.integration-state.validated') }} />
                    </Link>
                    <Link to={`/manage-dossier/statut-integration/sync`} className="dashboard__filter  dashboard__filter__sync">
                      <div className="filter__icon"><SynchroSuccess color="var(--ink-1)" /></div>
                      <div className="filter__number">{signedSync}</div>
                      <div className="filter__label" dangerouslySetInnerHTML={{ __html: t('dashboard.integration-state.sync') }} />
                    </Link>
                    <Link to={`/manage-dossier/statut-integration/error`} className="dashboard__filter  dashboard__filter__error">
                      <div className="filter__icon"><SynchroError color="var(--ink-1)" /></div>
                      <div className="filter__number">{signedSyncError}</div>
                      <div className="filter__label" dangerouslySetInnerHTML={{ __html: t('dashboard.integration-state.error') }} />
                    </Link>
                    <Link to={`/manage-dossier/statut-integration/refused`} className="dashboard__filter  dashboard__filter__refused">
                      <div className="filter__icon"><SynchroRefused color="var(--ink-1)" /></div>
                      <div className="filter__number">{signedSyncRefused}</div>
                      <div className="filter__label" dangerouslySetInnerHTML={{ __html: t('dashboard.integration-state.refused') }} />
                    </Link>
                  </div>
                </div>
              </div>
            </>
          </Tab>
          ]
        </Tabs>
      }
      {role !== ERole.ManageDossier &&
        <div className="min-height-content">
          <div className="box-elevations box-elevations--base box-elevations--max-heigt-initial box-elevations--mb u-mxAuto">
            {((role !== 'client' && role !== 'prospect_direct' && !isBackOfficeUser) || (role == 'prospect_direct')) &&
              <div className={`h4 help-wrapper u-mbs title-create-folder`}>{t('folder.create-for')}</div>
            }
            <div className="flex-container heading-folder-cta">
              {role !== 'client' && role !== 'prospect_direct' && !isBackOfficeUser &&
                <>
                  <div className="col-md-6">
                    <div className="button__container">
                      <Link to={'/creer-un-dossier-client'}
                            className="create-folder button button-primary button--large button--width-full">
                        {t(envVariable('REACT_APP_VARIABLES_CREATE_FOLDER_CUSTOMER_BUTTON', 'folder.create-customer'))}
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="button__container">
                      <a href="#" onClick={() => createFileWithoutClient()}
                        className="create-folder-prospect button button-primary button--large button--width-full">
                        {t(envVariable('REACT_APP_VARIABLES_CREATE_FOLDER_LEAD_BUTTON', 'folder.create-lead'))}
                      </a>
                    </div>
                  </div>
                </>
              }
              {role == ERole.DirectProspect &&
                <>
                  <div className="col-md-12">
                    <div className="button__container">
                      <a href="#" onClick={() => createFileWithoutClient()}
                        className="create-folder-prospect button button-primary button--large button--width-full">{t('folder.create-folder')}</a>
                    </div>
                  </div>
                </>
              }
            </div>
            <div className="dashboard__filters">
              <Link to={`/dossier/signed-files`} className="dashboard__filter dashboard__filter__signed">
                <div className="filter__icon"><SignedFiles color="var(--ink-1)"/></div>
                <div className="filter__number">{signedFiles}</div>
                <div className="filter__label" dangerouslySetInnerHTML={{__html: t('dashboard.signed-files')}}/>
              </Link>
              <Link to={`/dossier/signature-files`} className="dashboard__filter dashboard__filter__signature">
                <div className="filter__icon"><SignatureFiles color="var(--ink-1)"/></div>
                <div className="filter__number">{signatureFiles}</div>
                <div className="filter__label" dangerouslySetInnerHTML={{__html: t('dashboard.signature-files')}}/>
              </Link>
              <Link to={`/dossier/current-cases`} className="dashboard__filter  dashboard__filter__current-cases">
                <div className="filter__icon"><CurrentCases color="var(--ink-1)"/></div>
                <div className="filter__number">{currentCases}</div>
                <div className="filter__label" dangerouslySetInnerHTML={{__html: t('dashboard.current-cases')}}/>
              </Link>
              <Link to={`/dossier/abandoned-files`} className="dashboard__filter  dashboard__filter__abandonned-files">
                <div className="filter__icon"><AbandonedFiles color="var(--ink-1)"/></div>
                <div className="filter__number">{abandonedFiles}</div>
                <div className="filter__label" dangerouslySetInnerHTML={{__html: t('dashboard.abandoned-files')}}/>
              </Link>
            </div>
          </div>
        </div>
      }
      </>
    <FooterComponent />
  </>
}

export default Dashboard;
