import React, { FC, useEffect, useState } from 'react'
import Block from '../../../domain/Block/Block'
import BlockComponent from './BlockComponent'
import { useAppDispatch, useAppSelector } from '../../../store/hook'
import FetchBlockGateway from '../../../gateway/Block/FetchBlockGateway'
import { setAllBlocks } from '../../../store/block/blocks'
import ValidateConditionUseCase from '../../../domain/Condition/UseCase/ValidateConditionUseCase'
import FormGateway from '../../../gateway/Form/FormGateway'
import FindAllBlocksUseCase from '../../../domain/Block/UseCase/FindAllBlocksUseCase'
import BlockValidationComponent from './BlockValidationComponent'
import { Link, useHistory } from 'react-router-dom'
import Next from '../svg/Next'
import BlockValidationSummaryComponent from './BlockValidationSummaryComponent'
import { useTranslation } from 'react-i18next'
import storage from '../../util/storage'
import {isVisibleConditionBlock} from '../../util/validateCondition'
import { roleBackOfficeList } from '../../../domain/User/Erole'

type Props = {
    pageId: string
    blockId: string | null
}

const getIndex = (blocks: Block[], blockId: string | null): number => {
    if (blockId !== null) {
        const lastBlockSubmittedIndex = blocks.findIndex(block => block.id === blockId)

        return blocks.findIndex((block, index) => {
            let isValidate = true

            if (block.condition) {
                const validateConditionUseCase = new ValidateConditionUseCase(new FormGateway())
                isValidate = validateConditionUseCase.execute(block.condition)
            }

            return (block.visible || isValidate) && index > lastBlockSubmittedIndex
        })
    }

    const dossierState = storage.getDossierState()
    let signIndex = -1
    const userRole = localStorage.getItem('role');
    if (dossierState !== 'ongoing' && (!userRole || !roleBackOfficeList.includes(userRole))) {
        signIndex = blocks.findIndex(block => {
            if (block && block.slug) {
                block.slug.includes('signature')
            }
        })
    }

    const validateConditionUseCase = new ValidateConditionUseCase(new FormGateway());
    const datas = validateConditionUseCase.formGatewayInterface.getCurrentValueForFormId();
    const blocksVisible = blocks.filter((block) => {
        if (block.condition) {
            return isVisibleConditionBlock(block, validateConditionUseCase.executeWithDatas(block.condition, datas));
        }
        return block.visible;
    });

    const index = blocks.findIndex((block) => block.id === blocksVisible[0].id)

    return index > signIndex ? index : signIndex
}

const BlocksComponent: FC<Props> = ({ pageId, blockId }) => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const [blocks, setBlocks] = useState<Block[] | null>(null)
    const role = localStorage.getItem('role')

    const storePages = useAppSelector(state => state.wizzardStep.pages)
    const pages = typeof storePages === 'object' ? storePages : JSON.parse(storePages)
    const currentPageIndex = pages ? pages.findIndex(p => pageId === p.id) : 0
    const nextPageIndex = currentPageIndex + 1
    const lastPageIndex = pages ? pages.length - 1 : currentPageIndex

    useEffect(() => {
        setBlocks(null)
        const findAllBlocksUseCase = new FindAllBlocksUseCase(new FetchBlockGateway())

        findAllBlocksUseCase.execute(pageId).then((blocks) => {
            if (blocks) {
                const nextBlockIndex = getIndex(blocks, blockId)
                if (nextBlockIndex !== -1) {
                    for (let i = 0; i < nextBlockIndex; i++) {
                        blocks[i].state = 'complete'
                    }
                    blocks[nextBlockIndex].state = 'progress'
                } else if (nextPageIndex && pages[nextPageIndex]) {
                    history.push(`/form/${pages[nextPageIndex].id}`)
                }

                dispatch(setAllBlocks(blocks.map(block => block.toJSON())))
                setBlocks(blocks)
            }
        })
    }, [dispatch, pageId, blockId])

    const renderBlocks = () => {
        return blocks?.map((block, index) => (
          role === 'bo' ? (
            <BlockValidationComponent key={index} pageId={pageId} blockId={block.id} />
          ) : (
            <BlockComponent key={index} pageId={pageId} blockId={block.id} />
          )
        ))
    };

    const renderNextStepButton = () => {
        if (role === 'bo' && currentPageIndex !== lastPageIndex) {
            return (
              <div className="card u-mxAuto flex-container end-lg">
                  <Link
                    to={`/form/${pages[nextPageIndex].id}`}
                    className="button button-primary button--large button--width-fit button-shadow-secondary u-pxl u-pys"
                  >
                      {t('comment.next-step')}
                      <Next />
                  </Link>
              </div>
            )
        }
        return null
    };

    // const renderValidationSummary = () => {
    //     if (role === 'bo' && currentPageIndex === lastPageIndex) {
    //         return <BlockValidationSummaryComponent />
    //     }
    //     return null
    // }

    return (
      <>
          {blocks && (
            <>
                {renderBlocks()}
                {renderNextStepButton()}
                {/*{renderValidationSummary()}*/}
            </>
          )}
      </>
    )
}

export default BlocksComponent
