import { createSlice } from '@reduxjs/toolkit'
import ValidateConditionUseCase from '../../domain/Condition/UseCase/ValidateConditionUseCase'
import FormGateway from '../../gateway/Form/FormGateway'
import { isVisibleConditionBlock } from '../../presentation/util/validateCondition'
import Block from '../../domain/Block/Block'

const validateConditionUseCase = new ValidateConditionUseCase(new FormGateway())

interface BlocksState {
  all: Block[] | null
  blocksVisible: Block[] | null
  blocksVisibleLength: number | null
}

const initialState: BlocksState = {
  all: null,
  blocksVisible: null,
  blocksVisibleLength: null
}

export const blocksSlice = createSlice({
  name: 'blocks',
  initialState,
  reducers: {
    setAllBlocks: (state, action) => {
      const datas = validateConditionUseCase.formGatewayInterface.getCurrentValueForFormId()
      const blocksVisible = action.payload.filter(block =>
        block.condition
          ? isVisibleConditionBlock(block, validateConditionUseCase.executeWithDatas(block.condition, datas))
          : block.visible
      )

      return {
        all: action.payload,
        blocksVisible,
        blocksVisibleLength: blocksVisible.length
      }
    },
    updateBlock: (state, action) => {
      if (!state.all) return

      const blockToUpdateIndex = state.all.findIndex(block => block.id === action.payload.id)
      if (blockToUpdateIndex === -1) return

      state.all = state.all.map((block, index) => ({
        ...block,
        state: index === blockToUpdateIndex
          ? action.payload.state
          : block.state
      }))

      const nextBlockVisibleIndex = state.all.findIndex((block, index) =>
          index > blockToUpdateIndex && (
            block.condition
              ? isVisibleConditionBlock(block, validateConditionUseCase.execute(block.condition))
              : block.visible
          )
      )

      if (nextBlockVisibleIndex !== -1) {
        state.all[nextBlockVisibleIndex].state = action.payload.stateNext
      }

      state.blocksVisible = state.all.filter(block =>
        block.condition
          ? isVisibleConditionBlock(block, validateConditionUseCase.execute(block.condition))
          : block.visible
      )
      state.blocksVisibleLength = state.blocksVisible.length
    },
    modifyBlock: (state, action) => {
      if (!state.all) return

      const modifyBlockIndex = state.all.findIndex(block => block.id === action.payload.id)
      if (modifyBlockIndex === -1) return

      state.all = state.all.map((block, index) => ({
        ...block,
        state: block.id === action.payload.id
          ? 'progress'
          : modifyBlockIndex < index
            ? 'waiting'
            : 'complete'
      }))

      state.blocksVisible = state.all.filter(block =>
        block.condition
          ? isVisibleConditionBlock(block, validateConditionUseCase.execute(block.condition))
          : block.visible
      )

      state.blocksVisibleLength = state.blocksVisible.length
    }
  }
})

export const { setAllBlocks, updateBlock, modifyBlock } = blocksSlice.actions
export default blocksSlice.reducer
