/* eslint-disable  @typescript-eslint/no-explicit-any */
import '@babel/polyfill'

import envVariable from '../../presentation/util/envVariable'
import Authentication from '../../oauth/Authentication'
import axios from 'axios'

export default class Caller {

  static initApiUrl(): string {
    return `${envVariable('REACT_APP_API_URL')}/${envVariable('REACT_APP_LOCALE')}`
  }

  static async executeGet(
    path: string,
    params: Record<string, string>,
    signal?: AbortSignal
  ): Promise<any> {
    let paramsString = ''

    if (Object.keys(params).length > 0) {
      paramsString = Object.keys(params).map(key => key + '=' + params[key]).join('&')
      paramsString = `?${paramsString}`
    }

    return axios.get(`${path}${paramsString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${Authentication.getToken()}`
      },
      signal
    })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          return null
        }
        if (error.response?.status === 401) {
          return Authentication.refreshToken().then(success => success ? this.executeGet(path, params, signal) : null)
        }
        return Promise.reject(error)
      })
  }

  static async executeGetResponseAsBlob(
    path: string,
    params: Record<string, string>,
    signal?: AbortSignal
  ): Promise<any> {
    let paramsString = ''

    if (Object.keys(params).length > 0) {
      paramsString = Object.keys(params).map(key => key + '=' + params[key]).join('&')
      paramsString = `?${paramsString}`
    }

    return axios.get(`${path}${paramsString}`, {
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${Authentication.getToken()}`
      },
      signal
    })
      .then(response => response.data)
      .catch(async (error) => {
        if (error.name === 'AbortError') {
          return null
        }
        if (error.response) {
          const contentType = error.response.headers['content-type']
          if (contentType && contentType.includes('application/json')) {
            const errorBlob = error.response.data
            const errorText = await errorBlob.text()
            const errorJson = JSON.parse(errorText)
            return Promise.reject(errorJson)
          }

          if (401 == error.response?.status) {
            const success = await Authentication.refreshToken()
            if (success) {
              return this.executeGet(path, params)
            }
          }
        }

        return Promise.reject(error)
      })
  }

  static async executePost(
    path: string,
    params: unknown,
    signal?: AbortSignal
  ): Promise<any> {
    return axios(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${Authentication.getToken()}`
      },
      data: JSON.stringify(params)
    })
      .then(response => response.data)
      .catch((error) => {
        if (error.name === 'AbortError') {
          return null
        }
        if (error.response?.status === 401) {
          return Authentication.refreshToken().then(success => success ? this.executePost(path, params, signal) : null)
        }
        return Promise.reject(error)
      })
  }

  static async executePostBinaryFile(
    path: string,
    params: unknown,
    signal?: AbortSignal
  ): Promise<any> {
    return axios.request({
      url: path,
      responseType: 'blob',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${Authentication.getToken()}`
      },
      data: JSON.stringify(params)
    })
      .then(response => response)
      .catch((error) => {
        if (error.name === 'AbortError') {
          return null
        }
        if (error.response?.status === 401) {
          return Authentication.refreshToken().then(success => success ? this.executePostBinaryFile(path, params, signal) : null)
        }
        return Promise.reject(error)
      })
  }

  static async executePut(
    path: string,
    params: unknown,
    signal?: AbortSignal
  ): Promise<any> {
    return axios(path, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
        'Authorization': `${Authentication.getToken()}`
      },
      data: JSON.stringify(params),
      signal
    })
      .then(response => response.data)
      .catch((error) => {
        if (error.name === 'AbortError') {
          return null
        }
        if (error.response?.status === 401) {
          return Authentication.refreshToken().then(success => success ? this.executePut(path, params, signal) : null)
        }
        return Promise.reject(error)
      })
  }

  static async executeDelete(
    path: string,
    params: unknown,
    signal?: AbortSignal
  ): Promise<any> {
    return axios(path, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
        'Authorization': `${Authentication.getToken()}`
      },
      data: params,
      signal
    })
      .then(response => response.status === 204 ? true : response.data)
      .catch((error) => {
        if (error.name === 'AbortError') {
          return null
        }
        if (error.response?.status === 401) {
          return Authentication.refreshToken().then(success => success ? this.executeDelete(path, params, signal) : null)
        }
        return Promise.reject(error)
      })
  }
}
