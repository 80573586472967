import NotEqualInterface from './NotEqualInterface';

export default class NotEqual implements NotEqualInterface {
  private isArrayString(str: string): boolean {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }
  private areArraysEqual(arr1: Array<unknown>, arr2: Array<unknown>): boolean {
    if (arr1.length !== arr2.length) return false

    const sortedArr1 = [...arr1].sort()
    const sortedArr2 = [...arr2].sort()

    return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2)
  }

  validate(valueToCompare: string, secondValue: unknown, type?: string): boolean {
    if (type === 'select' && Array.isArray(secondValue) && this.isArrayString(valueToCompare)) {
      const arrToCompare = JSON.parse(valueToCompare);
      return !this.areArraysEqual(arrToCompare, secondValue);
    }

    if (secondValue === undefined && valueToCompare === "undefined") {
      return false
    }

    if (type === 'input_date' && secondValue !== undefined) {
      const [day, month, year] = String(secondValue).split("/");
      const date = new Date(Number(year), Number(month) - 1, Number(day));
      const today = new Date()

      if (isNaN(date.getTime())) {
        return false;
      }

      let age = today.getFullYear() - date.getFullYear();

      const birthMonth = date.getMonth();
      const birthDay = date.getDate();
      if (today.getMonth() < birthMonth || (today.getMonth() === birthMonth && today.getDate() < birthDay)) {
          age--;
      }
      return age !== Number(valueToCompare);
    }

    if (!isNaN(Number(String(valueToCompare))) && !isNaN(Number(secondValue))) {
      return Number(String(valueToCompare)) !== Number(secondValue);
    }

    return String(valueToCompare) !== String(secondValue ?? '')
  }
}
