export enum OperatorDefinition {
  EQUAL = 'equal',
  NOT_EQUAL = 'not_equal',
  GREATER = 'greater',
  GREATER_EQUAL = 'greater_equal',
  SMALLER = 'smaller',
  SMALLER_EQUAL = 'smaller_equal',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'not_contains'
}
