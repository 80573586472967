import {createSlice} from '@reduxjs/toolkit'

// Define a type for the slice state
interface WizzardStepState {
  pages: string | null,
  prevPageId: string | null,
  currentPageId: string | null,
  nextPageId: string | null,
  isBack: boolean | false,
  firstPage: null
}

// Define the initial state using that type
const initialState: WizzardStepState = {
  pages        : null,
  prevPageId   : null,
  currentPageId: null,
  nextPageId   : null,
  isBack       : false,
  firstPage    : null
}

export const wizzardStepSlice = createSlice({
  name        : 'wizzardStep',
  initialState: initialState,
  reducers    : {
    setAllPages: (state, action) => {
      state.currentPageId = JSON.parse(action.payload)[0].id
      state.pages = action.payload
    },
    updateProgressValueById: (state, action) => {
      if (state.pages) {
        const pages = JSON.parse(state.pages)

        const newObjects = pages.map(page => {
          if (page.id === action.payload.id) {
            page.progress = action.payload.progress
          }

          return page
        })

        state.pages = JSON.stringify(newObjects)
      }
    },
    updateProgressValueByIdGoBack: (state, action) => {
      if (state.pages) {
        const pages = JSON.parse(state.pages);
        const hasPayloadId = action.payload.id && action.payload.id !== null;
        const currentPageId = hasPayloadId ? action.payload.id : state.currentPageId;
        const currentPageIdIndex = pages.findIndex(page => page.id === currentPageId);

        if (currentPageIdIndex === -1) {
          console.error('Error: currentPageId not found in pages');
          return;
        }

        pages[currentPageIdIndex].progress = hasPayloadId ? action.payload.progress : 0;

        const currentPageIdIndexUpdate = hasPayloadId ? currentPageIdIndex + 1 : currentPageIdIndex - 1;

        if (currentPageIdIndexUpdate >= 0 && currentPageIdIndexUpdate < pages.length) {
          pages[currentPageIdIndexUpdate].progress = 0;
        } else {
          console.warn(`Skipping update: currentPageIdIndexUpdate ${currentPageIdIndexUpdate} is out of bounds`);
        }

        state.pages = JSON.stringify(pages);
      }
    },
    updateNextPageId: (state, action) => {
      if (state.pages) {
        const pages = JSON.parse(state.pages)

        const nextPageIndex = pages.findIndex(page => page.id === action.payload.id ?? pages[0])
        state.prevPageId = typeof(pages[nextPageIndex - 1]) != 'undefined' ? pages[nextPageIndex - 1].id : null
        state.currentPageId = typeof(pages[nextPageIndex]) != 'undefined' ? pages[nextPageIndex].id : null
        state.nextPageId = (pages && (typeof(pages[nextPageIndex + 1]) != 'undefined') && pages[nextPageIndex + 1].id) ? pages[nextPageIndex + 1].id : null
      }
    },
    updateNextPageIdAndCurrentPageIddByGoBack: (state, action) => {
      if (state.pages) {
        const pages = JSON.parse(state.pages)
        const nextPageIndex = pages.findIndex(page => page.id === action.payload.id)

        state.currentPageId = (pages && pages[nextPageIndex - 1].id) ? pages[nextPageIndex - 1].id : null
        state.nextPageId = (pages && pages[nextPageIndex].id) ? pages[nextPageIndex].id : null
      }
    },
    isBack: (state, action) => {
      state.isBack = action.payload.back
    },
    getFirstPage: (state) => {
      if (state.pages) {
        const pagesList = JSON.parse(state.pages)
        let firstPage = pagesList[0]
        let minRank = firstPage.rank
        pagesList.forEach((page) => {
          if (page.rank < minRank) {
            firstPage = page;
            minRank = page.rank;
          }
        })

        state.firstPage = firstPage
      }
    }
  }
})

export const {
  setAllPages,
  updateProgressValueById,
  updateProgressValueByIdGoBack,
  updateNextPageId,
  updateNextPageIdAndCurrentPageIddByGoBack,
  isBack,
  getFirstPage
} = wizzardStepSlice.actions

export default wizzardStepSlice.reducer
